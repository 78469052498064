%pin {
	&-subject {
		@supports (position: sticky) {
			position: sticky !important;
			top: 0;
		}
		@supports not (position: sticky) {
			position: fixed !important;

			&.js-stuck {
				position: absolute !important;
				bottom: 0;
			}

			@include no-js-fallback {
				position: absolute !important;
			}
		}
	}

	&-wrapper {
		@supports (position: sticky) {
			overflow: visible;
			max-width: 100%;
		}
	}
}

.pin {
	@extend %pin-subject;
}
.pin-wrapper {
	@extend %pin-wrapper;
}
