@each $key,
	$value
		in map-merge(
			$colors,
			(
				"black": black,
				"white": white,
			)
		)
{
	.#{$key}-text {
		color: $value !important;
	}
	.#{$key}-bg {
		&,
		& .inherited-bg {
			background-color: $value !important;
		}
		// color: color-contrast($value, $text-main, $text-reversed)
	}
	// .#{$key}-border {
	//     border-color: $value !important;
	// }
}

.inherit-bg {
	background: inherit !important;
}

.dim {
	&-light {
		opacity: 0.75;
	}

	&,
	&-medium {
		opacity: 0.5;
	}

	&-extra {
		opacity: 0.2
	}
}
