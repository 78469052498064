//------------------------------------------------------------------------------
// FEATURE ALTERNATIVES
//
// For providing alternative styles when using features that don't fail nicely
// in certain browsers. **COUGH** internet explorer **COUGH**

// Set up alternative styles when using CSS filters
@mixin filter-alt {
	@include ie-only {
		-ms-filter: "";
		filter: "";

		@content;
	}

	// MS Edge 12 only
	@supports (-ms-accelerator: true) and (not (color: unset)) {
		filter: none;

		@content;
	}
}

/// Don't apply content rules in browsers that don't support display: grid
/// * Edge 12-15 supports different spec of css grid, hence special query
@mixin if-supports-grid {
	@supports (display: grid) and ((not (-ms-ime-align: auto)) or (position: sticky)) {
		@content;
	}
}

@mixin grid-alt {
	@include ie-only {
		@content;
	}

	// MS Edge 12 - 15
	@supports (-ms-ime-align: auto) and (not (position: sticky)) {
		@content;
	}
}

@mixin dark-mode {
	@media screen and (prefers-color-scheme: dark) {
		@content;
	}
}

@mixin light-mode {
	@media screen and (prefers-color-scheme: light) {
		@content;
	}
}
