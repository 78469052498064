.aspect-wrapper {
	position: relative;
	width: 100%;

	& > * {
		@include snippet("size-parent");
	}

	&.aspect {
		&-21x9,
		&-ultrawide {
			padding-top: 100% / (21/9);
		}

		&-12x5,
		&-anamorphic {
			padding-top: 100% / (12/5);
		}

		&-16x9,
		&-hd {
			padding-top: 100% / (16/9);
		}

		&-4x3,
		&-sd {
			padding-top: 75%;
		}

		&-1x1,
		&-square {
			padding-top: 100%;
		}
	}
}
