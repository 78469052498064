@mixin media-portrait {
	@media (orientation: portrait) {
		@content;
	}
}

@mixin media-landscape {
	@media (orientation: landscape) {
		@content;
	}
}

@mixin media-breakpoint-not($names, $breakpoints: $grid-breakpoints) {
	$queries: ();
	$sorted-names: ();
	@each $breakpoint-name in map-keys($map: $breakpoints) {
		@if list-includes($names, $breakpoint-name) {
			$sorted-names: append($sorted-names, $breakpoint-name);
		}
	}
	@each $name in $sorted-names {
		$prev: map-get-prev($breakpoints, $name, false, key);
		$next: map-get-next($breakpoints, $name, false, key);
		$query: ();
		@if $prev and not list-includes($sorted-names, $prev) {
			$query: append($query, media-breakpoint-down($prev));
		}
		@if $next and not list-includes($sorted-names, $next) {
			$query: append($query, media-breakpoint-up($next));
		}
		$queries: append($queries, list-implode($query, ", "));
	}
	@media #{list-implode($queries, " and ")} {
		@content;
	}
}

@mixin media-retina {
	@media (min-resolution: 150dpi) {
		@content;
	}
}

@mixin media-reduced-motion {
	@media screen and (prefers-reduced-motion: reduce) {
		@content;
	}
}

@mixin media-dark-mode {
	@media screen and (prefers-color-scheme: dark) {
		@content;
	}
}

@mixin media-light-mode {
	@media screen and (prefers-color-scheme: light) {
		@content;
	}
}
