@each $align,
	$values
		in (
			"left": (
				left,
			),
			"right": (
				right,
			),
			"center": (
				center,
			),
			"justify": (
				justify,
			),
			"start": (
				left,
				start,
			),
			"end": (
				right,
				end,
			)
		)
{
	.ta-#{$align} {
		@each $value in $values {
			text-align: $value !important;
		}
	}
}

@each $dec,
	$decoration
		in (
			"under": underline,
			"through": line-through,
			"over": overline,
			"blink": blink,
			"none": none
		)
{
	.td-#{$dec} {
		text-decoration-line: $decoration !important;
	}
}
@each $dec in (wavy, double, dotted, dashed, solid) {
	.td-#{$dec} {
		text-decoration-style: $dec !important;
	}
}

.inherit-text {
	letter-spacing: inherit !important;
	line-height: inherit !important;
	font-family: inherit !important;
	font-size: inherit !important;
	font-weight: inherit !important;
}

.serif {
	font-family: $font-family-serif !important;
}

.sans,
.sans-serif {
	font-family: $font-family-sans !important;
}

.display {
	font-family: $font-family-display !important;
}

.leading {
	&-xs {
		line-height: $line-height-xs !important;
	}
	&-sm {
		line-height: $line-height-sm !important;
	}
	&-md,
	&-normal {
		line-height: $line-height-main !important;
	}
	&-lg {
		line-height: $line-height-lg !important;
	}
}

// .ls {
// 	&-relaxed,
// 	&-lg {
// 		letter-spacing: $tracking-lg !important;
// 	}
// 	&-md {
// 		letter-spacing: $tracking-md !important;
// 	}
// 	&-snug,
// 	&-base {
// 		letter-spacing: $tracking-base !important;
// 	}
// 	&-sm {
// 		letter-spacing: $tracking-sm !important;
// 	}
// 	&-xs {
// 		letter-spacing: $tracking-xs !important;
// 	}
// 	&-xxs {
// 		letter-spacing: $tracking-xxs !important;
// 	}
// 	&-tight {
// 		letter-spacing: $tracking-tight !important;
// 	}
// 	&-ultra-tight {
// 		letter-spacing: $tracking-ultra-tight !important;
// 	}
// }

.lighter {
	font-weight: lighter !important;
}
.bolder {
	font-weight: bolder !important;
}
.light {
	font-weight: $font-weight-light !important;
}

.normal {
	font-weight: normal !important;
}

.medium,
.semi-bold,
.semibold {
	font-weight: $font-weight-semi-bold !important;
}
.bold {
	font-weight: $font-weight-bold !important;
}
// .extra-bold {
// 	font-weight: $font-weight-black !important;
// }

.tt {
	&-upper {
		text-transform: uppercase !important;
	}
	&-lower {
		text-transform: lowercase !important;
	}
	&-none {
		text-transform: none !important;
	}
	&-title {
		text-transform: capitalize !important;
	}
}
