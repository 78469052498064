.footer {
	@include font-size($font-size-sm);
	line-height: $line-height-sm;
	font-family: $font-family-sans;
	padding: 2rem 0;
	display: flex;
	justify-content: space-around;

	a {
		transition: opacity $default-timing;
		@include link-style {
			color: inherit;
		}
		@include a11y-focus(":hover") {
			opacity: 0.5;
		}
	}

	&-credits {
		margin-top: 0.25rem;
	}

	&-production {
		&-credit {
			margin-bottom: 1.25rem;
			max-width: 30em;
		}
		&-logos {
			$footer-logo-gap: 1.25rem;
			@include snippet(flex-center);
			margin: (-$footer-logo-gap / 2);
			margin-top: 1rem - ($footer-logo-gap / 2);
			flex-wrap: wrap;

			@include media-breakpoint-up(sm) {
				flex-wrap: nowrap;
			}

			> div {
				// @include snippet(flex-center);
				padding: ($footer-logo-gap / 2);
			}

			> hr {
				pointer-events: none;
				border: none;
				opacity: 0;
				visibility: hidden;
				flex: 1 0 100%;
				// display: none;
				margin: 0;
				padding: 0;
			}
		}
	}

	&-social {
		overflow: hidden;

		.social-icons {
			min-height: 2.875em;
		}

		// @include media-breakpoint-down(sm) {
		// 	order: 2;
		// 	align-self: center;
		// }
	}

	&-meta {
		$meta-items-gap: $line-height-main * 1em;
		display: flex;
		justify-content: center;
		align-items: baseline;
		margin: 1.5rem (-$meta-items-gap / 2) 0;
		opacity: 0.75;

		> small {
			margin: 0 ($meta-items-gap / 2);
		}

		a {
			@include a11y-focus(":hover") {
				text-decoration: underline;
			}
			html.touch & {
				text-decoration: underline;
			}
		}
	}

	@include media-breakpoint-down(md) {

		.row {
			justify-content: space-around;
			align-items: center;
		}

		&-credits,
		&-social,
		&-meta {
			margin-top: 30px;
		}

	}

	@include media-breakpoint-only(xs) {
		padding: 12.5% 10%;

		.row {
			> div {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&-meta {
			text-align: center;

			&,
			& > small {
				display: block;
			}

			> small {
				margin: 0.25em;
			}
		}

		&-production-logos {
			justify-content: space-evenly;
		}
	}

	@include media-breakpoint-between(sm, md) {
		&-credits {
			order: 3;
		}

		&-social {
			order: 2;
			align-self: center;
			margin-top: 0;
		}
	}
}
