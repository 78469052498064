%header-content-animation-paused {
	@include when-pager-active {
		opacity: 0;
	}
}

.conflict {
	&-trigger {
		position: relative;
		text-shadow: 0 0 20px rgba(black, 0.3);
	}

	&-header {
		&-title {
			color: $linen;
			margin: 0 auto (1em * 1.25 / 7);
			line-height: 1.125;

			.conflict-trigger:not(.pager-hide-js) & {
				animation: simpleFadeIn time(0.5s) ease-out backwards;
			}
			.conflict-trigger.pager-hide-js & {
				@extend %header-content-animation-paused;
			}
		}
		&-dates {
			margin: -0.25rem auto 0;

			.conflict-trigger:not(.pager-hide-js) & {
				animation: simpleFadeIn time(0.5s) time(0.25s) ease-out backwards;
			}
			.conflict-trigger.pager-hide-js & {
				@extend %header-content-animation-paused;
			}
		}
		&-explore {
			@extend %btn-font;
			position: absolute;
			left: 50%;
			padding-top: 1em;
			transform: translate(-50%);
			min-width: 7em;

			.conflict-trigger:not(.pager-hide-js) & {
				animation: simpleFadeIn time(0.5s) time(0.5s) ease-out backwards;
			}
			.conflict-trigger.pager-hide-js & {
				@extend %header-content-animation-paused;
			}

			img {
				margin-inline-end: 0.25em;
				vertical-align: middle;
				position: relative;
				bottom: 0.1em;
			}

			div.conflict-trigger & {
				display: none;
			}
		}

		.pager-expanded > &,
		body.conflict_template & {
			&:not(:last-child) {
				min-height: 5em;
				@include snippet(flex-center);

				.header-content-scroll {
					position: relative;
					overflow: visible;
				}
			}
		}

		.pager-expanded > & &,
		body.conflict_template & {
			&-explore {
				opacity: 0;
			}

			&-dates {
				@include font-size($h2-font-size * 0.7);
			}
		}

		@supports (width: fit-content) {
			&-content a {
				margin: 0 auto;
			}
			&-content a,
			&-title,
			&-dates,
			&-explore {
				width: fit-content;
				display: block;
			}
		}

		&-title,
		&-dates,
		&-explore {
			opacity: inherit;
		}
	}

	&-main {
		width: 100%;
	}

	@import "./conflicts/synopsis";

	@import "./conflicts/location";

	@import "./conflicts/factions";

	@at-root #commanders {

		.commander > * {
			@include media-breakpoint-down(xs) {
				margin-left: $grid-gutter-width !important;
				margin-right: $grid-gutter-width !important;
			}
		}

		figcaption {
			top: 50%;
			margin: 2em;

			@include media-breakpoint-only(md) {
				top: unset;
				margin: 1em;
			}
		}

		figure {
			@include media-breakpoint-down(sm) {
				max-width: percentage(10 / 12);
				margin: 0 auto;
			}
			@include media-breakpoint-only(xs) {
				max-width: percentage(11 / 12);
			}
		}
	}

	@import "./conflicts/weapons";

	&-next {
		$conflict-next-timing: time(1s) ease;
		transition: padding-top $conflict-next-timing,
			padding-bottom $conflict-next-timing;

			&::before,
			&::after {
				@include snippet(pseudo-bg);
				opacity: 0.7;
				width: 150%;
				padding-top: 60%;
				background: radial-gradient(
					ellipse farthest-side at 33% 50%,
					transparent,
					rgba(black, 0.9)
				);
				transform: translate(-50%, -50%);
				left: 50%;
				top: 50%;
				z-index: $layer-bg-overlay;
				transition: background-color $conflict-next-timing;

				@include media-breakpoint-down(xs) {
					background: radial-gradient(
						ellipse farthest-side at 55% 50%,
						transparent,
						rgba(black, 0.9)
					);					}
			}

			&-bg {
				@include snippet(centered-absolute);
				z-index: $layer-bg;
				pointer-events: none;
				transition: transform $conflict-next-timing;
				min-height: 100%;
				min-width: 100%;
				max-width: unset;
				width: auto !important;
				height: auto !important;
			}


		&-wrapper {
			overflow: hidden;
			display: block;

			&:hover,
			&.tab-focus:focus {
				.conflict-next {
					@include media-breakpoint-up(xl) {
						padding-top: 20px;
						padding-bottom: 20px;
					}

					&-bg {
						transform: translate(-50%, -50%) scale(1.125);
					}

					&::before {
						background-color: rgba($black, 0.4);
					}
				}
			}
		}

		&,
		.container {
			z-index: $layer-content;
		}

		.container {
			p,
			img {
				z-index: $layer-content-super;
			}
			strong {
				letter-spacing: tracking(20);
			}
		}

		@at-root .next {
			&-arrow {
				@extend .ssb-arrow;
				align-self: center;

				@include media-breakpoint-down(xs) {
					margin-top: 0 !important;
					margin-left: auto;
				}
			}
		}
	}

	&-back {
		.next-arrow {
			margin-left: 0;
			margin-right: auto;
			right: unset;
			left: 0;
			transform: rotate(90deg);
			@include media-breakpoint-up(sm) {
				transform: translateY(-50%) rotate(90deg);
				left: 5px;
			}
		}
	}

	.header-content-scroll {
		@include snippet(flex-center);
	}
}

#pivotal-battle {
	min-height: 100vh;
	z-index: $layer-content-super;
}

.pivotal-battle {
	&-bg {
		overflow: hidden;
		z-index: $layer-content;
		@extend %pin-subject;
		@supports (position: sticky) {
			+ section {
				margin-top: -100vh;
				padding-top: 100vh;
				margin-bottom: 150vh;
			}
		}

		img {
			min-width: 100%;
			min-height: 100vh;
			// width: auto;
			height: auto;
			max-width: unset;
			position: relative;
			z-index: $layer-bg;
			left: 50%;
			transform: translateX(-50%);
			width: 100vw;
		}
	}

	&-wrapper {
		@extend %pin-wrapper;
		display: flex;
		flex-flow: column nowrap;
	}

	&-content {
		h3 {
			margin-bottom: 1em;
		}
	}
}

#aftermath {
	margin-top: -10%;
	z-index: $layer-content;
	position: relative;
}
