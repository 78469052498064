@mixin font-face(
	$name,
	$filename,
	$weight: normal,
	$style: normal,
	$format-exts: ("eot", "woff2", "woff", "ttf")
) {
	@font-face {
		font-family: quote($name);
		$filename: quote($filename);
		$srcs: ();
		$formats: ();
		@if list-includes($format-exts, "eot") {
			src: get-url(FONT_DIR, $filename + ".eot");
			$srcs: append($srcs, get-url(FONT_DIR, $filename + ".eot?#iefix"));
			$formats: append($formats, 'format("embedded-opentype")');
		}
		@if list-includes($format-exts, "woff2") {
			$srcs: append($srcs, get-url(FONT_DIR, $filename + ".woff2"));
			$formats: append($formats, 'format("woff2")');
		}
		@if list-includes($format-exts, "woff") {
			$srcs: append($srcs, get-url(FONT_DIR, $filename + ".woff"));
			$formats: append($formats, 'format("woff")');
		}
		@if list-includes($format-exts, "ttf") {
			$srcs: append($srcs, get-url(FONT_DIR, $filename + ".ttf"));
			$formats: append($formats, 'format("truetype")');
		}
		src: zip(list-map($srcs, unquote), list-map($formats, unquote));
		font-weight: $weight;
		font-style: $style;
	}
}

// Set letter-spacing using tracking value (eg: from Photoshop)
@mixin letter-spacing($tracking) {
	letter-spacing: 1em * $tracking / 1000;
}

// Rem output with px fallback
@mixin font-size($size, $important: false) {
	@if $enable-responsive-font-sizes and mixin-exists("rfs") {
		@include rfs($size, $important);
	} @else {
		$size_value: strip-unit($size);
		@if unit($size) == rem {
			font-size: $size_value * 16px;
		}
		font-size: $size;
		@if list-includes((px, pt), unit($size)) {
			font-size: ($size_value / 16 * 1rem);
		}
		@if unit($size) == pc {
			font-size: ($size_value * 4 / 3 * 1rem);
		}
	}
}

// Set responsive font-size and leading from pixel values
@mixin font-leading($font-size, $leading-size) {
	@include font-size($font-size * 1px);
	line-height: $leading-size / $font-size;
}
