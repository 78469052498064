@at-root #location {
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;

	@include media-portrait {
		@include media-breakpoint-down(sm) {
			background-size: 150% auto;
		}
	}

	&::before,
	&::after {
		padding-top: 10%;
	}

	.container {
		z-index: $layer-content;
		position: relative;
	}

	@at-root .location {
		&-map {
			flex: 0 0 auto;
			width: auto;
			max-width: 100%;
			$transition-timing: time(0.75s) ease-out;

			img {
				max-width: 100%;
				width: auto;
			}
		}
		&-blob {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: auto;
			height: auto;
			// background: linear-gradient(
			// 		45deg,
			// 		transparent calc(50% - 1px),
			// 		cyan 50%,
			// 		transparent calc(50% + 1px)
			// 	),
			// 	linear-gradient(
			// 		-45deg,
			// 		transparent calc(50% - 1px),
			// 		cyan 50%,
			// 		transparent calc(50% + 1px)
			// 	),
			// 	linear-gradient(
			// 		0deg,
			// 		transparent calc(50% - 1px),
			// 		white 50%,
			// 		transparent calc(50% + 1px)
			// 	),
			// 	linear-gradient(
			// 		90deg,
			// 		transparent calc(50% - 1px),
			// 		white 50%,
			// 		transparent calc(50% + 1px)
			// 	);

			@keyframes blobPulse {
				from {
					opacity: 0.5;
				}
				to {
					opacity: 1;
				}
			}

			animation: blobPulse time(1.25s) cubic-bezier(0.445, 0.05, 0.55, 0.95)
				infinite alternate;

			@supports (filter: contrast()) {
				filter: contrast(1.25);
			}

			@include a11y-reduce-motion;
		}
	}
}

.conflict-category {
	.inline-svg {
		max-width: 100%;
		height: auto;
	}

	text {
		fill: $linen;
		text-transform: uppercase;
	}

	.categories {
		opacity: 0.8;
		font-family: $font-family-sans;
		font-size: 11px;
		letter-spacing: tracking(25);
		font-weight: $font-weight-semi-bold;
	}

	&.show-active {
		.cat {
			transition-property: fill, opacity, font-size;
			transition-duration: time(1.25s);
			transition-timing-function: ease;
		}

		@for $i from 1 through 5 {
			&.cat-#{$i}-active {
				.cat-#{$i} {
					fill: red;
					font-weight: $font-weight-bold;
					font-size: 12px;
					opacity: 1;
					text-shadow: 0 0 6px rgba($black, 0.3);
				}
			}
		}
	}
}
