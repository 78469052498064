.nav {
	$nav-transition-timing: 300ms ease;

	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background-color: transparent;
	z-index: $layer-nav;
	font-family: $font-family-display;
	padding: 1em ($line-height-main * 1em);
	transition: background-color $nav-transition-timing, opacity $nav-transition-timing,
		transform $nav-transition-timing;

	@include js-only {
		&.nav-hide-js {
			opacity: 0;
			transform: translateY(-101%);
		}
	}

	@include no-js-fallback {
		background: $black;
	}

	&::before {
		@include snippet(pseudo-bg);
		transition: opacity $nav-transition-timing;
		background-color: inherit;
		box-shadow: 2px 2px 6px 0 $overlay-medium;
	}

	&:not(.nav-fill) {
		@include js-only {
			&,
			& .row,
			& .nav-content {
				pointer-events: none;
			}

			& .nav {
				&-home,
				&-social,
				&-lang,
				&-close {
					pointer-events: auto !important;
				}
			}

			&::before {
				opacity: 0;
			}
		}
	}

	> .row {
		@include font-size($font-size-xs);
		align-items: center;
	}

	&-home {
		margin-right: 1em;
	}

	&-content {
		.row {
			align-items: center;
		}
	}

	&-heading {
		margin-right: 1em;
		transition: opacity $nav-transition-timing;

		> h1 {
			padding-bottom: 0.01em;
			@include media-breakpoint-up(xl) {
				br {
					display: none;
				}
			}
		}

		&:empty {
			display: none;
		}
	}

	&-body {
		font-size: inherit;
		display: flex;
		align-items: center;
		list-style: none;
		flex-wrap: wrap;
		white-space: nowrap;

		.nav:not(.nav-fill) & {
			@include js-only {
				pointer-events: none;
				opacity: 0;
			}
		}

		> li {
			margin: 0 0.725vw;
			display: block;

			@media (min-width: 1440px) {
				margin: 0 0.75em;
			}
		}

		&:empty {
			display: none;
		}
	}

	&-anchor,
	&-mobile > select {
		color: $linen;
		text-transform: uppercase;
		letter-spacing: tracking(35);
	}

	&-anchor {
		opacity: 0.6;
		transition: opacity $nav-transition-timing;

		@include a11y-focus(":hover") {
			color: $linen;
			text-decoration: underline;
			opacity: 1;
		}

		&.active {
			text-decoration: underline;
			opacity: 1;

			ul:hover &:not(:hover) {
				opacity: 0.6;
			}
		}
	}

	&-social {
		@media (max-width: 359.98px) {
			display: none;
		}
	}

	.social-icons {
		img {
			display: block;
		}
	}

	&-lang {
		padding-left: 1em;
		padding-right: 1em;

		a {
			@extend %body-copy-small;
			letter-spacing: tracking(25);
			transition: font-size time(300ms) ease time(200ms);

			.cancelable & {
				transition-delay: 0s !important;
				@include font-size(px-to-rem(12px));
			}
		}
	}

	&-close {
		color: $linen;
		max-width: 0;
		margin-left: 0;
		margin-right: -10px;
		opacity: 0;
		visibility: hidden;
		transition: max-width time(300ms) ease, margin-left time(300ms) ease,
			margin-right time(300ms) ease, opacity $default-timing,
			visibility 0s time(301ms);

		.cancelable & {
			visibility: visible;
			transition: max-width time(300ms) ease, margin-left time(300ms) ease,
				margin-right time(300ms) ease, opacity $default-timing;
			transition-delay: time(200ms);
			max-width: 2.8em;
			margin-left: -0.5em;
			margin-right: 0;
			opacity: 1;
		}

		> a {
			color: inherit;
		}

		svg {
			color: currentColor;
		}

		@include a11y-focus(":hover") {
			opacity: 0.75;
		}
	}

	&-trigger {
		display: flex; // prevents collapsing margins
		height: 0;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;

		.header ~ &,
		.home-header-wrapper ~ & {
			margin-top: -80px;
			margin-bottom: 80px;
		}
	}

	@include js-only {
		&-hide-js {
			opacity: 0;
			pointer-events: none;
		}
	}

	&-mobile {
		display: none;
		transition: opacity $nav-transition-timing;

		@include media-breakpoint-only(xs) {
			transition: opacity $nav-transition-timing, margin-top $nav-transition-timing;

			&.nav-hide-js {
				margin-top: -2.5em;
			}
		}

		@media (max-width: 1023.98px) {
			// @include media-breakpoint-down(lg) {
			display: block;

			@at-root .nav-body {
				display: none;
			}
		}

		select {
			cursor: pointer;
			display: block;
			padding: 0.5em;
			padding-right: 2em;
			border: none;
			-webkit-appearance: none;

			background: img-url("angle.svg") right center/1em auto no-repeat;

			&:active,
			&:focus-within,
			&:focus,
			&:hover {
				text-decoration: underline;

				option,
				optgroup {
					color: black;
					background-color: white;
				}

				option:checked {
					background: linen;
				}
			}

			&:empty {
				display: none;
			}

			@include media-breakpoint-down(sm) {
				@include safari-only {
					// transition: font-size $default-timing;
					// &:active {
						font-size: 1rem !important;
					// }
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		&-lang a {
			@include font-size(px-to-rem(12px));
		}

		&-heading {
			display: none;
		}

		&-body,
		&-close {
			.home & {
				display: none;
			}
		}
		&-content {
			margin-right: auto;
		}
		&-social {
			margin-left: auto;
			margin-right: auto;
		}

		&-lang {
			margin-left: auto;
		}

		&.cancelable &-social {
			display: none;
		}
	}

	@include media-breakpoint-only(xs) {
		br {
			order: 3;
		}

		&-content {
			margin: 0 auto;
			order: 4;
			margin-top: 0.5em;
		}
		&-home {
			margin-right: auto;
		}
		&-mobile {
			@include flex-center;
		}
	}
}
