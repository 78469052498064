/*********************
SPACING STYLES
*********************/
@each $breakpoint, $modifier in (xl: 1, md: 0.875, xs: 0.75) {
	@include media-breakpoint-down($breakpoint) {
		@for $spacing-factor from 1 through 13 {
			@if not list-includes((11, 12), $spacing-factor) {
				$space: $spacing-factor * 10;
				$value: ceil($space * $modifier) * 1px;
				@each $prefix, $property in (m: margin, p: padding) {
					@each $dir, $direction in (t: top, b: bottom) {
						%#{$prefix + $dir}-#{$space} {
							#{$property}-#{$direction}: $value;
						}
						.#{$prefix + $dir}-#{$space} {
							#{$property}-#{$direction}: $value !important;
						}
					}
				}
			}
		}
	}
}

@each $prefix, $property in (m: margin, p: padding) {
	.#{$prefix}-em {
		#{$property}: 1em !important;
	}
	@each $size,
		$value
			in (
				xs: $line-height-xs,
				sm: $line-height-sm,
				md: $line-height-md,
				lg: $line-height-lg
			)
	{
		.#{$prefix}-lead-#{$size} {
			#{$property}: $value * 1em !important;
		}
		@each $dir, $direction in (t: top, b: bottom, l: left, r: right) {
			.#{$prefix + $dir + "-lead-" + $size} {
				#{$property}-#{$direction}: $value * 1em !important;
			}
		}
	}

	.#{$prefix}-inherit,
	.#{$prefix}-bestow > * {
		#{$property}: inherit !important;
	}

	@each $dir, $direction in (t: top, b: bottom, l: left, r: right) {
		.#{$prefix + $dir}-inherit,
		.#{$prefix + $dir}-bestow > * {
			#{$property}-#{$direction}: inherit !important;
		}
	}

	.#{$prefix}-0 {
		#{$property}: 0 !important;
	}

	@each $dir, $direction in (t: top, b: bottom, l: left, r: right) {
		.#{$prefix + $dir}-0 {
			#{$property}-#{$direction}: 0 !important;
		}
	}
}

.mb-h {
	margin-bottom: $headings-margin-bottom !important;
}

.mx-auto,
.ml-auto {
	margin-left: auto !important;
}
.mx-auto,
.mr-auto {
	margin-right: auto !important;
}

%mb-last-0 > *:last-child {
	margin-bottom: 0;
}
.mb-last-0 > *:last-child {
	margin-bottom: 0 !important;
}
%mt-first-0 > *:first-child {
	margin-top: 0;
}
.mt-first-0 > *:first-child {
	margin-top: 0 !important;
}
