@at-root .conflict {
	&-factions {
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 100% auto;
		// position: relative;
		z-index: $layer-content;

		@include media-portrait {
			@include media-breakpoint-down(sm) {
				background-size: 200% auto;
			}
		}

		&-vs {
			$vs-base-font-size: 21px;
			$vs-bg-color: rgba($linen, 0.37);
			$vs-line-width: 2px;
			$vs-width: 46px;
			$vs-size: px-to-em($vs-width, $vs-base-font-size);
			text-align: center;
			align-self: flex-start;
			@include font-size(px-to-rem($vs-base-font-size));
			line-height: ($vs-width / $vs-base-font-size);
			width: $vs-size;
			border-radius: $border-radius-circle;
			// background: $vs-bg-color;

			@include media-breakpoint-down(md) {
				// position: relative;
				margin: 1.5em;
			}

			@each $name, $color in $theme-colors {
				$vs-bg-color: mix($linen, $color, 37%);

				.#{$name}-bg & {
					background: $vs-bg-color;

					&::before,
					&::after {
						color: $vs-bg-color
					}
				}
			}

			&::before,
			&::after {
				@extend %h4-font; // uses same font-size as section title for spacing calculations
				@include snippet(pseudo);
				display: block;
				position: absolute;
				border: 0 solid currentColor;
				@include media-breakpoint-down(sm) {
					.col-md-2 > & {
						font-size: inherit !important;
						border-top-width: $vs-line-width;
						top: 50%;
						width: calc(50% - #{$vs-size / 2} - 15px);
					}
				}
				@include media-breakpoint-down(md) {
					.col-lg-1 > & {
						font-size: inherit !important;
						border-top-width: $vs-line-width;
						top: 50%;
						width: calc(50% - #{$vs-size / 2} - 15px);
					}
				}
			}
			&::after {
				left: 50%;

				.col-md-2 > & {
					@include media-breakpoint-down(sm) {
						transform: translate(calc(#{$vs-size / 2}), -50%);
					}
					@include media-breakpoint-up(md) {
						border-left-width: $vs-line-width;
						height: calc(100% + 2.625em + #{$font-size-base} - 1px);
						transform: translateX(-50%);
					}
				}
				.col-lg-1 > & {
					@include media-breakpoint-down(md) {
						transform: translate(calc(#{$vs-size / 2}), -50%);
					}
					@include media-breakpoint-up(lg) {
						border-left-width: $vs-line-width;
						height: calc(100% + 2.625em + #{$font-size-base} - 1px);
						transform: translateX(-50%);
					}
				}
			}

			&::before {
				right: 50%;
				transform: translate(calc(-#{$vs-size / 2}), -50%);
				@include media-breakpoint-up(md) {
					.col-md-2 > & {
						display: none;
					}
				}
				@include media-breakpoint-up(lg) {
					display: none;
				}
			}


			&.-continued {
				background: none;

				&::after {
					bottom: 0;
				}
			}

			@at-root .vs-wrapper {
				position: relative;
				display: inline-block;
				left: 0.1em;
				// padding-top: px-to-em($grid-gutter-width, $vs-base-font-size);
			}

			// @include media-breakpoint-down(md) {
			// 	&::after {
			// 		display: none;
			// 	}
			// }
		}
	}

	@at-root {
		.section#allies {
			min-height: 60vh;
			min-height: 40vmax;
		}
	}

	@at-root .factions {
		&-col {
			& ~ & {
				transition-delay: time(0.5s);
			}
		}
	}

	@at-root .faction {
		&-title {
			font-family: $font-family-sans;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			margin-bottom: 1.5em;

			&:last-child {
				margin-bottom: 0;
			}

			.faction-combatant & {
				line-height: 1.1;

				&:not(:last-child) {
					margin-bottom: $line-height-xs * 1em;
				}
			}
		}

		$flag-size: 32px;
		$flag-offset: calc(#{$flag-size} + 0.5em);

		&-ally {
			img {
				font-size: inherit;
				width: $flag-size;
				height: auto;
				margin-left: calc(-1 * #{$flag-offset});
				margin-right: 0.325em;
			}
		}

		&-allies {
			line-height: 2;

			&.-flagged {
				padding-left: $flag-offset;
			}
		}

		// &-description {
		// 	margin-right: -3em;
		// }

		&.-described {
			// flex-basis: 29%;
			// max-width: 29%;
		}
	}

	@at-root #allies {
		position: relative;
		&::before,
		&::after {
			@include snippet(pseudo);
			width: 50%;
			padding-top: 50%;
			left: 50%;
			top: 9.25em;
			position: absolute;
			background: img-url("triangle-fade-tip.svg") center/100% 100% no-repeat;
			transform-origin: bottom left;
			transform: translateY(-100%);
			z-index: $layer-bg-overlay;
			pointer-events: none;
		}
		&::after {
			transform: translateY(calc(-100% - 1px)) rotate(180deg);
		}
	}
}
