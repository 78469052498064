@mixin sr-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */

	&:focus {
		background-color: whitesmoke;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		clip-path: none;
		color: black;
		display: block;
		@include font-size(0.875);
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}

.sr-only {
	@include sr-text;
}

.semantic-only {
	@extend %inherit-text;
	margin-bottom: 0;
}

@mixin a11y-focus($add-ons: ()) {
	$current: &;
	$selectors: (selector-append($current, ".tab-focus:focus"));
	@each $add-on in $add-ons {
		$selectors: append($selectors, selector-append($current, $add-on));
	}
	@at-root #{selector-parse($selectors)} {
		@content;
	}
}

*:focus,
button:focus {
	outline: 0;
}

* {
	@include a11y-focus {
		outline: auto;
	}
}
$touch-target-size: 45px !default;

%min-touch-target {
	min-height: $touch-target-size;
	min-width: $touch-target-size;
}

%min-touch-target-pseudo {
	@include snippet("pseudo");
	@include snippet("centered-absolute");
	@extend %min-touch-target;
	width: 100%;
	height: 100%;
	z-index: 1;
}

@mixin a11y-assert-min-touch-target($pseudo-element: null) {
	overflow: visible;
	@if index((before, after), $pseudo-element) {
		&#{ "::" + $pseudo-element} {
			@extend %min-touch-target-pseudo;
		}
	} @else {
		@extend %min-touch-target;
	}
}

.a11y-min-target {
	@include a11y-assert-min-touch-target();

	&-before {
		@include a11y-assert-min-touch-target(before);
	}

	&-after {
		@include a11y-assert-min-touch-target(after);
	}
}

@media screen and (-ms-high-contrast: active) {
	/* All high contrast styling rules */
	body {
		color: windowText;
		background: window;
	}

	a:link,
	a:visited {
		color: highlight;
		text-decoration: underline;

		&:hover {
			background: highlight;
			color: windowText;
		}
	}

	.sr-only.high-contrast {
		width: initial;
		position: initial !important;
		margin: initial;
		height: initial;
		clip-path: initial;
		clip: initial;
	}
}

@media screen and (-ms-high-contrast: black-on-white) {
	/* Rules for users using black-on-white */
}
@media screen and (-ms-high-contrast: white-on-black) {
	/* Rules for users using white-on-black */
}

html.no-js .js-only,
html:not(.no-js) .no-js-only {
	display: none !important;
}

%a11y-reduce-motion {
	@include media-reduced-motion {
		transition: none !important;
		animation: none !important;
	}
}
@mixin a11y-reduce-motion {
	@extend %a11y-reduce-motion;
}
