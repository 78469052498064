.home {
	background: inherit;
	$intro-glow: 0 0 7px rgba($white, 0.5);

	&-header {
		&-background {
			overflow: hidden;
			z-index: $layer-bg;
			height: 100vh;
			width: 100%;
			@extend %pin-subject;
		}
		@supports (position: sticky) {
			&-background + &-panel {
				margin-top: -100vh;
			}
		}

		&-wrapper {
			@extend %pin-wrapper;
			z-index: $layer-content;
		}

		&-panel {
			font-size: inherit;
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			text-shadow: $intro-glow;
			min-height: 100vh;
			padding-top: 3.5em;
			padding-bottom: 3.5em;

			@include media-breakpoint-up(sm) {
				padding-top: 5em;
				padding-bottom: 5em;
			}
			@include media-breakpoint-up(md) {
				padding-top: 7em;
				padding-bottom: 7em;
			}
		}
	}

	&-intro {
		strong {
			font-weight: $font-weight-bold;

			&:first-of-type {
				font-size: 1.181818em;
			}
		}
	}

	&-broadcast {
		&::before {
			@include snippet(pseudo);
			border-top: 3px solid currentColor;
			display: block;
			width: 3.5rem;
			margin: 1.2em auto;
			box-shadow: $intro-glow;
		}
	}

	&-trailer {
		@include font-size(px-to-rem(200px));
		letter-spacing: tracking(-10);
		font-family: $font-family-display;
		color: rgba($white, 0.1);
		line-height: 1;
		margin-bottom: -0.275em;
	}

	.creatives-intro {
		@include media-breakpoint-up(md) {
			margin-left: 1.166667em;
			margin-right: 1.166667em;
		}
	}

	.m-lead-lg {
		@include media-breakpoint-down(sm) {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	#creatives,
	#meet-the-host {
		.section-copy {
			@include media-breakpoint-up(md) {
				padding-left: 0;
			}
		}
	}
}

#home-logo {
	@keyframes homeLogo {
		0% {
			filter: blur(40px);
			transform: scale(0.75);
			opacity: 0;
		}

		80% {
			opacity: 1;
			filter: blur(0);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes homeLogo_alt {
		0% {
			transform: scale(0.75);
			opacity: 0;
		}

		80% {
			opacity: 1;
		}

		100% {
			transform: scale(1);
		}
	}

	animation: homeLogo time(1.75s) time(0.3s) ease-out both;

	@include filter-alt {
		animation: homeLogo_alt time(1.75s) time(0.3s) ease-out both;
	}

	@include a11y-reduce-motion;
}

#title a {
	position: absolute;
	bottom: 3em;

	svg,
	img {
		@keyframes homeArrow {
			from {
				transform: translateY(-75%);
				opacity: 0;
			}
			to {
				transform: translateY(0);
				opacity: 1;
			}
		}
		animation: homeArrow time(0.5s) time(3s) ease-out both;

		@include a11y-reduce-motion;
	}
}

#meet-the-host {
	.row {
		align-items: center;
	}

	img {
		opacity: 0.63;
	}
}

body.home {
	.nav {
		@media (max-width: 1023.98px) {
			&:not(.nav-hide-js) {
				pointer-events: auto !important;
				background: inherit !important;

				&::before {
					opacity: 1 !important;
				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	#desktop-bg-video {
		display: none;
	}
}
@include media-breakpoint-up(md) {
	#mobile-bg-video {
		display: none;
	}
}
