body {
	color: $text-main;
	background: $body-bg;
}

.site {
	background: inherit;
}

.main-inner {
	animation: simpleFadeIn time(0.5s) time(0.8s) ease-out both;
}
