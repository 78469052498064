%fade-in {
    transition: opacity time(1s) ease;

	&.hide-js {
		transition: none;
		@include js-only {
			opacity: 0;
			pointer-events: none;
		}
	}
}

.fade-in {
	@extend %fade-in;

	&-each > * {
		@extend %fade-in;
	}

	&-with-delay {
		@extend %fade-in;
	}
}

.-delayed {
	$standard-delay: time(0.25s);

	&,
	&-x1 {
		transition-delay: $standard-delay;
	}

	@for $mult from 2 through 4 {
		&-x#{$mult} {
			transition-delay: $mult * $standard-delay
		}
	}
}

@keyframes simpleFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
