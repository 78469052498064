%clear {
	content: " ";
	display: table;
}

%clearfix,
.clearfix,
.clear {
	*zoom: 1;
	&:before {
		@extend %clear;
	}
	&:after {
		@extend %clear;
		clear: both;
	}
}
