//==============================================================================
// SNIPPETS
//
// Mixins for short snippets. When possible, use the snippet() mixin to reduce
// filesize of final stylesheet
//==============================================================================

@mixin size-parent($centered: false) {
	position: absolute;
	width: 100%;
	height: 100%;
	@if $centered == true {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else {
		top: 0;
		left: 0;
	}
}

@mixin pseudo($variant: null) {
	content: "";
	display: block;

	@if index((bg, background), $variant) != null {
		@include size-parent;
		pointer-events: none;
		z-index: $layer-bg;
	} @else if $variant == overlay {
		@include size-parent;
		z-index: $layer-overlay;
	} @else if $variant == overtop {
		@include size-parent;
		z-index: $layer-overtop;
	} @else if index((center, centered), $variant) != null {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@mixin hidden($interactive: false) {
	opacity: 0;
	@if $interactive == false {
		pointer-events: none;
	}
}

@mixin unhidden {
	opacity: 1;
	visibility: visible;
	pointer-events: initial;
}

@mixin flex-column {
	display: flex;
	flex-flow: column nowrap;
}

@mixin flex-level {
	display: flex;
	flex-flow: row nowrap;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
}

@mixin flex-fill {
	align-items: stretch;
	display: flex;
	flex-wrap: nowrap;
	justify-content: stretch;
}

@mixin centered-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@mixin centered-flex {
	align-self: center;
	justify-self: center;
}

@mixin centered-absolute($x: true, $y: true) {
	position: absolute;
	@if $x == true and $y == true {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	} @else if $x == true {
		left: 50%;
		transform: translateX(-50%);
	} @else if $y == true {
		top: 50%;
		transform: translateY(-50%);
	}
}

@mixin shine {
	@supports (filter: contrast()) {
		filter: contrast(0.5) brightness(1.4);
	}
	// filter: brightness(1.1);
	@include filter-alt {
		opacity: 0.85;
	}
}

%snippet {
	&-size-parent {
		@include size-parent;

		&-centered {
			@include size-parent(true);
		}
	}

	&-pseudo {
		@include pseudo;

		&-bg,
		&-background {
			@include pseudo(bg);
		}

		&-overlay {
			@include pseudo(overlay);
		}

		&-overtop {
			@include pseudo(overtop);
		}

		&-center,
		&-centered {
			@include pseudo(center);
		}
	}

	&-hidden {
		@include hidden;

		&-interactive {
			@include hidden(true);
		}

		&-undo {
			@include unhidden;
		}
	}

	&-flex {
		&-column {
			@include flex-column;
		}

		&-center {
			@include flex-center;
		}

		&-fill {
			@include flex-fill;
		}
		&-level {
			@include flex-level;
		}
	}

	&-centered {
		&-block {
			@include centered-block;
		}

		&-flex {
			@include centered-flex;
		}

		&-absolute {
			@include centered-absolute(true, true);

			&-y {
				@include centered-absolute(false, true);
			}
		}
	}

	&-shine {
		@include shine;
	}
}

// Use this to reduce stylesheet size by extending instead of re-including
// every time
@mixin snippet($style-group) {
	@extend %snippet-#{$style-group};
}
