@mixin pseudo-fade($color: currentColor, $height:100px, $bottom_height: -1, $elements: all) {
	@if $bottom_height == -1 {
		$bottom_height: $top_height;
	}

	@if list-includes((all, common), $elements) {
		position: relative;

		&::before,
		&::after {
			@include snippet(pseudo);
			position: absolute;
			width: 100%;
			left: 0;
			z-index: $layer-bg-overlay;
			pointer-events: none;
			padding-top: $height;
		}
	}

	@if list-includes((all, before, pseudo), $elements) {
		&::before {
			top: -1px;
			background: linear-gradient(to bottom, $color, rgba($color, 0));
		}
	}

	@if list-includes((all, after, pseudo), $elements) {
		&::after {
			bottom: -1px;
			background: linear-gradient(to top, $color, rgba($color, 0));
		}
	}
}
