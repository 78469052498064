//------------------------------------------------------------------------------
// BROWSER HACKS
//
// For getting around weird things in certain browsers
// Courtesy of https://browserstrangeness.github.io/css_hacks.html
//
// * NB: With the exception of IE, these browsers are still actively maintained
// and these hacks could be affected by future releases. If possible, avoid
// using them for any critical CSS, or monitor their effectiveness over
// the lifetime of the project.

@mixin ie-only {
	// Internet Explorer 9-11
	@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
		@content;
	}
}

@mixin edge-only {
	// Microsoft Edge 12+ (as of Jan 2019)
	@supports (-ms-ime-align: auto) {
		@content;
	}
}

@mixin safari-only {
	// Safari 9+, iOS Chrome, iOS Firefox (as of Jan 2019)
	@supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
		@content;
	}
}

@mixin firefox-only {
	// Firefox 35+ (as of Jan 2019)
	//* NB: iOS Firefox uses the Safari engine, and will not be affected by this
	@supports (-moz-appearance: none) and (mask-type: alpha) {
		@content;
	}
}

@mixin chrome-only {
	// Chrome 29+, Opera 16+ (as of Jan 2019)
	//* NB: iOS Chrome uses the Safari engine, and will not be affected by this
	@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
		@content;
	}
}


@mixin no-js-fallback {
	// .no-js class removed by render-blocking js
	html.no-js & {
		@content;
	}
}

@mixin js-only {
	// .no-js class removed by render-blocking js
	html:not(.no-js) & {
		@content;
	}
}
