%btn-reset {
	-webkit-appearance: none;
	background: none;
	border: none;
	overflow: hidden;
	font-size: inherit;
	line-height: inherit;
	outline: none;
	padding: 0;
	text-align: inherit;
	text-transform: inherit;
	user-select: none;
}

@mixin link-style {
	&,
	&:visited {
		@content;
	}
}

%btn {
	@extend %btn-reset;
	@extend %btn-font;

	&:focus {
		box-shadow: none;
	}
}

.btn {
	@extend %btn;
	color: inherit;
	$btn-padding-v: $line-height-sm * 1em;

	&:not(.-bare) {
		display: inline-block;


		padding: $btn-padding-v ($line-height-main * 1em);
		border: 2px solid currentColor;
		border-radius: $border-radius-pill;

		@include a11y-focus(":hover") {
			color: inherit;
			opacity: 0.5;
		}
	}

	&.-narrow {
		padding: $btn-padding-v ($line-height-xs * 1em);
	}

	&.-wide {
		padding: $btn-padding-v 2em;
	}

	&.-circle {
		@include snippet(flex-center);
		min-width: 4.125em;
		min-height: 4.125em;
		max-width: 4.125em;
		max-height: 4.125em;
		white-space: nowrap;
		text-align: center;

		> .btn-icon {
			margin: 0;
		}
	}

	&.-bare {
		@include a11y-focus(":hover") {
			outline: none;
		}
	}

	&-icon {
		position: relative;
		vertical-align: middle;
		bottom: 0.1em;
		margin-right: 0.5em;
		font-size: inherit;
		height: $line-height-xs * 1em; // btn font line height
		width: auto;
	}

	&-list {
		$btn-list-gap: 10px !default;
		$btn-list-margin: ($btn-list-gap / 2);

		padding: 0;
		margin: -$btn-list-margin;
		list-style: none;
		display: flex;
		flex-flow: row wrap;

		& > &-item {
			margin: $btn-list-margin;
		}
	}
}

a {
	img,
	.cover-image-wrapper {
		transition: opacity 0.3s ease;
		transition: opacity 0.3s ease, filter 0.3s ease;
		backface-visibility: hidden; // Fixes image wobble with opacity
	}

	@include a11y-focus(":hover") {
		text-decoration: none;

		&:link {
			img,
			.cover-image-wrapper {
				// @supports (filter: contrast()) {
				// 	filter: contrast(0.5) brightness(1.4);
				// }
				// @include filter-alt {
					// opacity: 0.75;
				// }
			}
		}
	}

	// &.shine {
	// 	@include a11y-focus(":hover") {
	// 		@include snippet(shine)
	// 	}
	// }

}
