.float {
	&-left {
		float: left !important;
	}
	&-right {
		float: right !important;
	}
}

@each $display in (block, inline, inline-block, flex) {
	.#{$display} {
		display: $display !important;
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	.hide-#{$breakpoint} {
		@include media-breakpoint-up($breakpoint) {
			display: none !important;
		}
		@include media-breakpoint-down($breakpoint) {
			&-down {
				display: none !important;
			}
		}
		@include media-breakpoint-only($breakpoint) {
			&-only {
				display: none !important;
			}
		}
	}
}

@each $position in (relative, absolute, fixed, static) {
	.#{$position} {
		position: $position;
	}
}

.overflow {
	@each $value in (hidden, visible, auto) {
		&-#{$value} {
			overflow: #{$value} !important;
		}
	}
}

.oval,
.circle {
	border-radius: $border-radius-circle !important;
}
.pill {
	border-radius: $border-radius-pill !important;
}
