@at-root {
	.conflict {
		&-gallery {
			$gallery-img-gutter: 12px / 2;
			margin-top: calc(2.5em - #{$gallery-img-gutter});
			margin-bottom: calc(2.5em - #{$gallery-img-gutter});
			@include snippet(flex-center);
			flex-wrap: wrap;

			@include media-breakpoint-up(xl) {
				margin-left: calc(-2em - #{$gallery-img-gutter});
				margin-right: calc(-2em - #{$gallery-img-gutter});
			}

			> a {
				/* Use a:nth-of-type() to style specific images */
				color: inherit;
				float: left;
			}

			> hr {
				/* Use a:nth-of-type() to style specific images */
				pointer-events: none;
				border: none;
				opacity: 0;
				visibility: hidden;
				flex: 1 0 100%;
				display: none;
				margin: 0;
				padding: 0;
			}

			figure {
				margin: $gallery-img-gutter;
			}
		}
	}
	#synopsis {
		background: img-url("triangle-fade-edge.svg") left top/50% auto no-repeat;

		.dropcap-first > p:first-child {
			transition: none;
			pointer-events: auto;
			animation: simpleFadeIn time(1s) ease time(0.5s) 1 normal both;

			.figure {
				// opacity: 0;
				@keyframes slideFade {
					from {
						transform: translateY(5%);
						opacity: 0;
					}
					to {
						transform: translateY(0);
						opacity: 1;
					}
				}
				animation: slideFade time(0.75s) ease time(0.75s) 1 normal both;

				.hide-js & {
					animation-play-state: paused;
				}
			}

			&,
			.figure {
				@include a11y-reduce-motion;
			}
		}

		@include media-breakpoint-down(sm) {
			a {
				figcaption {
					display: none;
				}
			}
		}
	}
}
