picture {
	display: block;
}

figure {
	margin-bottom: 0;
	position: relative;
	width: fit-content;

	&::before {
		content: "";
		display: block;
		@include snippet(size-parent);
		background-color: inherit;
		opacity: 0;
		pointer-events: none;
		transition: opacity time(0.3s) ease;
		z-index: $layer-overlay;
	}

	.hover &:hover,
	a.tab-focus:focus > & {
		&::before {
			opacity: 0.75;
		}
		figcaption {
			opacity: 1;
		}
	}
}

figcaption {
	@extend %caption-font;
	font-weight: $font-weight-semi-bold;
	text-align: center;
	padding: 1em;
	margin: 0;
	position: absolute;
	z-index: $layer-overtop;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	transition: opacity time(0.2s) ease;
}

img {
	@extend %caption-font;
	max-width: 100%;
	height: auto;
}

%cover-image,
.cover-image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	&-wrapper {
		@extend %cover-image;

		img {
			opacity: 0;
		}

		&.align {
			&-left {
				background-position: left center;
			}
			&-right {
				background-position: right center;
			}
			@each $v-align in (top, bottom) {
				&-#{$v-align} {
					background-position: center $v-align;
					@each $h-align in (left, right) {
						&-#{$h-align} {
							background-position: $h-align $v-align;
						}
					}
				}
			}
		}
	}
}

.figure {
	@extend figure;
}

span.caption {
	@extend figcaption;
}

.inline-image {
	float: left;
	margin-top: 0.25em;
	margin-right: 0.75em;
	margin-bottom: 0.2em;

	@include media-breakpoint-down(sm) {
		margin: 1em 0 !important;

		&-wrapper {
			float: left;
			display: flex;
			justify-content: center;
			width: 100%;
		}
	}
}

.inline-svg {
	> div,
	svg {
		max-width: 100%;
	}
}
