.header {
	@include font-size($h1-font-size);

	&:not(.pin) {
		@include snippet(flex-center);
		position: relative;
	}
	z-index: $layer-nav-sub;

	&::before {
		@include snippet(centered-absolute);
		content: "";
		z-index: $layer-bg-overlay !important;
		display: block;
		width: 200%;
		height: 200%;
		pointer-events: none;
		background: radial-gradient(ellipse closest-side at center, transparent, black);
	}
	&-background {
		@include snippet(centered-absolute);
		z-index: $layer-bg;
		display: block;
		min-height: calc(100% + 2px);
		min-width: calc(100% + 2px);
		max-width: unset;
		max-height: unset;
		width: auto;
		height: auto;
		pointer-events: none;

		@at-root img.header-background + video.header-background {
			@include media-breakpoint-down(md) {
				display: none;
			}
			@include media-reduced-motion {
				display: none;
			}
		}
	}

	video::-webkit-media-controls {
		display: none;
	}

	&-particles {
		pointer-events: none;
		@include snippet(centered-absolute);
		width: 110%;
		height: 110%;

		> canvas {
			transition: opacity 500ms ease-out;

			&.particles-hide {
				opacity: 0;
			}
		}
	}

	&-content {
		z-index: $layer-content;
		position: relative;
		text-align: center;

		a {
			&,
			&:hover {
				color: inherit;
				text-decoration: none;
			}

			&:link:hover {
				opacity: 0.75;
			}
		}

		&-scroll {
			@include snippet(flex-center);
			padding: 2.25em 2rem;

			@include media-breakpoint-up(sm) {
				padding: 1.5em 0;
			}
			@include media-breakpoint-up(lg) {
				padding: 1.25em 4rem;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				overflow-x: hidden;
				overflow-y: auto;
				@include custom-scrollbar;
			}
		}
	}
}
