@mixin safari-pivotal-bg-dimensions($nat-width, $nat-height) {
	height: calc(100vw * #{$nat-height} / #{$nat-width}) !important;
	min-width: calc(100vh * #{$nat-width} / #{$nat-height}) !important;
}

.conflict_template {
	&.iroquois-wars {
		.conflict-gallery {
			flex-flow: column wrap;
			justify-content: flex-start;

			$left: 392 / (392 + 463);
			$right: 463 / (392 + 463);

			@include media-breakpoint-down(md) {
				a {
					width: percentage($right) * 10 / 12;
					&:first-of-type {
						width: percentage($left) * 10 / 12;
					}
				}
				max-height: 500px;
			}
			@include media-breakpoint-down(sm) {
				a {
					width: percentage($right);
					&:first-of-type {
						width: percentage($left);
					}
				}
				max-height: 400px;
			}
			@include media-breakpoint-down(xs) {
				max-height: 80vw;
			}
			@include media-breakpoint-up(lg) {
				max-height: 700px;
			}
		}

		.location {
			&-description {
				max-width: 29em;
			}

			&-blob {
				top: 61.5%;
				left: 73.5%;
				width: percentage(261.35 / 575);
			}
		}

		.pivotal-battle-bg img {
			@include safari-pivotal-bg-dimensions(1440, 1818);
		}
	}

	&.king-philips-war {
		.conflict-gallery {
			align-items: flex-start;

			@include media-breakpoint-down(lg) {
				a {
					flex: 0 1 calc((100% - 1px) / 3);
				}
			}
		}

		.location {
			&-description {
				max-width: 29em;
			}

			&-blob {
				left: 78%;
				top: 63%;
				width: percentage(252.57 / 575);
			}
		}

		.pivotal-battle-bg img {
			@include safari-pivotal-bg-dimensions(1438, 1082);
		}
	}

	&.seven-years-war {
		.conflict-gallery {
			@include media-breakpoint-down(lg) {
				a {
					flex: 0 1 45%;
					align-self: flex-end;

					&:nth-of-type(n + 3) {
						align-self: flex-start;
					}
					&:nth-last-of-type(3),
					&:nth-last-of-type(2) {
						flex-basis: 40%;
					}
					&:last-of-type {
						flex-basis: 20%;
					}
				}
			}
		}

		.location {
			&-blob {
				top: 53.4%;
				left: 46.7%;
				width: percentage(487 / 828);
			}
		}

		.pivotal-battle-bg img {
			@include safari-pivotal-bg-dimensions(1440, 1000);
		}
	}

	&.pontiacs-war {
		.conflict-gallery {
			a {
				align-self: flex-start;
				&:nth-last-of-type(n + 3) {
					align-self: flex-end;
				}
			}
			@include media-breakpoint-up(xl) {
				> hr:nth-of-type(3) {
					display: block;
				}
				a {
					&:nth-of-type(3) {
						margin-right: 190px;
					}
					&:nth-of-type(4) {
						margin-left: 190px;
					}
				}
			}
			@include media-breakpoint-down(lg) {
				a {
					flex-basis: 25%;

					&:first-of-type,
					&:last-of-type {
						flex-basis: 40%;
					}
				}
			}
			@include media-breakpoint-down(md) {
				a {
					flex-basis: 30%;
				}
			}
		}
		.location {
			&-blob {
				top: 39.5%;
				left: 72.1%;
				width: percentage(235 / 770);
			}
		}

		.pivotal-battle-bg img {
			@include safari-pivotal-bg-dimensions(1440, 803);
		}
	}

	&.american-revolutionary-war {
		.conflict-gallery {
			@include media-breakpoint-down(md) {
				a {
					flex-basis: 60%;

					&:first-of-type {
						flex-basis: 31.95%;
					}
					&:nth-of-type(3) {
						flex-basis: 60.95%;
					}
					&:last-of-type {
						flex-basis: 39.05%;
					}
				}
			}
		}
		.location {
			&-blob {
				top: 61%;
				left: 71%;
				width: percentage(399 / 772);
			}
		}
		.pivotal-battle-bg img {
			@include safari-pivotal-bg-dimensions(1440, 813);
		}
	}

	&.red-clouds-war {
		.conflict-gallery {
			@include media-breakpoint-up(xl) {
				a {
					&:nth-of-type(4) {
						margin-left: 154px;
					}
				}
			}
			@include media-breakpoint-down(lg) {
				$top-row-nat-width: 286px + 422px + 462px;
				a {
					align-self: flex-end;
					&:nth-of-type(n + 4) {
						align-self: flex-start;
					}
					&:first-of-type {
						flex-basis: percentage(286px / $top-row-nat-width);
					}
					&:nth-of-type(2) {
						flex-basis: percentage(422px / $top-row-nat-width);
					}
					&:nth-of-type(3) {
						flex-basis: percentage(462px / $top-row-nat-width);
					}
					&:nth-of-type(4) {
						flex-basis: percentage(281px / $top-row-nat-width);
						margin-left: percentage(154px / $top-row-nat-width);
					}
					&:last-of-type {
						flex-basis: percentage(471px / $top-row-nat-width);
					}
				}
			}
		}
		.location {
			&-blob {
				left: 35%;
				top: 39%;
				width: 36%;
			}
		}
		.pivotal-battle-bg img {
			@include safari-pivotal-bg-dimensions(1438, 779);
		}
	}

	&.nez-perce-war {
		.conflict-gallery {
			> a {
				flex: 0 1 33%;
				align-self: flex-end;
			}

			@include media-breakpoint-up(xl) {
				display: block;

				> a {
					float: left;
				}
				margin-left: -40px;
				margin-right: -40px;
			}
		}
		.location {
			&-blob {
				left: 18.25%;
				top: 23.5%;
				width: percentage(339 / 770);
			}
		}

		.pivotal-battle-bg img {
			@include safari-pivotal-bg-dimensions(1440, 772);
		}
	}

	&.northwest-resistance {
		.conflict-gallery {
			align-items: flex-start;
			@include media-breakpoint-only(md) {
				> a {
					&:nth-of-type(7) {
						flex-basis: 33%;
					}
				}
			}
			@include media-breakpoint-down(md) {
				> a {
					flex: 0 1 25%;
					align-self: flex-end;

					&:nth-of-type(4) ~ a {
						align-self: flex-start;
					}

					&:nth-of-type(5) {
						margin-left: auto;
					}
				}
			}
			@include media-breakpoint-only(lg) {
				> a:nth-of-type(6) {
					order: 7;
					position: relative;
					right: 53px;
				}
			}

			@include media-breakpoint-up(xl) {
				margin-left: -4px;
				margin-right: -4px;
				> a {
					&:nth-of-type(4) {
						margin-right: auto;
					}
					&:nth-of-type(5) {
						margin-left: auto;
					}
				}
			}
		}

		.location {
			&-blob {
				left: 36.5%;
				top: 53.25%;
				width: percentage(274.51 / 648.13);
			}
		}
		.pivotal-battle-bg img {
			@include safari-pivotal-bg-dimensions(1438, 827);
		}
	}
}

.img {
	&-kp-header-bg-jpg {
		height: calc(1024 / 1836 * 100vw) !important;
	}
	&-sy-header-bg-jpg {
		height: calc(1024 / 1833 * 100vw) !important;
	}
	&-po-header-bg-jpg {
		height: calc(1024 / 1835 * 100vw) !important;
	}
	&-ar-header-bg-jpg {
		height: calc(1024 / 1837 * 100vw) !important;
	}
	&-rc-header-bg-jpg {
		height: calc(1024 / 1852 * 100vw) !important;
	}
	&-np-header-bg-jpg {
		height: calc(1024 / 1830 * 100vw) !important;
	}
	&-nr-header-bg-jpg {
		height: calc(1024 / 1823 * 100vw) !important;
	}
}
