@mixin when-ssb-preview {
	.ssb-preview & {
		@content;
	}
}

@mixin when-conflicts-intro {
	#conflicts-intro & {
		@content;
	}
}

$conflict-intro-delay: 1.5s;

.ssb {
	&-naw-presents {
		@extend %h5-font;
		font-family: $font-family-sans;
		font-weight: $font-weight-semi-bold;
		letter-spacing: tracking(25);
		text-transform: uppercase;
		color: $white;
		max-width: 10em;
		margin: 0 auto 1.5em;

		@include when-ssb-preview {
			@extend %caption-font;
			letter-spacing: tracking(145);
			max-width: unset;
		}

		@include when-conflicts-intro {
			@include media-breakpoint-up(lg) {
				animation: simpleFadeIn time(0.3s) time(0, $conflict-intro-delay) ease-out
					both;
			}
		}
		@include a11y-reduce-motion;
	}

	&-title {
		padding-bottom: 0.454545em;
		margin-top: 0.454545em;
		margin-bottom: -2.35%;

		@include when-ssb-preview {
			margin-top: 0;
		}

		@include when-conflicts-intro {
			@include media-breakpoint-up(lg) {
				animation: simpleFadeIn time(0.5s) time(0.5s, $conflict-intro-delay)
					ease-out both;
			}
			@media (min-height: 900px) {
				margin-top: 1.454545em;
			}
		}
		@include a11y-reduce-motion;
	}

	&-subtitle {
		@include font-size(px-to-rem(46));
		line-height: $line-height-xs;
		font-family: $font-family-sans;
		font-weight: $font-weight-semi-bold;
		color: $linen;
		margin-bottom: 0;

		@include when-ssb-preview {
			@extend %h4-font;
			font-weight: $font-weight-bold;
			text-shadow: 0 0 6px rgba($black, 0.3);
		}
		@include when-conflicts-intro {
			@include media-breakpoint-up(lg) {
				animation: simpleFadeIn time(0.5s) time(1.25s, $conflict-intro-delay)
					ease-out both;
			}
		}
		@include a11y-reduce-motion;
	}

	&-intro {
		@extend %h5-font;
		font-family: $font-family-sans;
		margin-top: 1em;
		opacity: 80%;

		@include when-conflicts-intro {
			@include media-breakpoint-up(lg) {
				animation: simpleFadeIn time(0.5s) time(1.75s, $conflict-intro-delay)
					ease-out both;
			}
		}
		@include a11y-reduce-motion;
	}

	&-scroll {
		display: block;
		width: fit-content;

		@supports not (width: fit-content) {
			max-width: 20em;
		}

		img {
			display: block;
			margin: 1.25rem auto 0;
		}

		@include media-breakpoint-up(md) {
			transform: translateY(50%);
		}

		@include when-conflicts-intro {
			@include media-breakpoint-up(lg) {
				animation: simpleFadeIn time(0.6s) time(2.25s, $conflict-intro-delay)
					ease-out both;
			}
		}
		@include a11y-reduce-motion;
	}

	&-arrow {
		width: 3em;
		height: 3em;
		border-radius: $border-radius-circle;
		transition: opacity time(0.3s) ease;
		margin-top: 2em;
		transform: rotate(-90deg);

		&:hover {
			opacity: 0.5;
		}

		@include media-breakpoint-up(sm) {
			position: absolute;
			right: ($grid-gutter-width / 2);
			top: 50%;
			transform: translateY(-50%) rotate(-90deg);
			margin-top: 0;
		}
	}

	&-preview {
		position: relative;
		overflow: hidden;
		z-index: $layer-content;

		&-bg {
			@extend .conflict-next-bg;
			transform-origin: center;
		}

		&:hover &-bg {
			@include media-breakpoint-down(lg) {
				transform: translate(-50%, -50%) scale(1.1);
			}
		}

		.container > .row {
			padding-top: 0;
			padding-bottom: 0;
			transition: padding-top time(1s) ease, padding-bottom time(1s) ease;
		}

		a:hover,
		a.tab-focus:focus {
			.container > .row {
				@include media-breakpoint-up(xl) {
					padding-top: 20px;
					padding-bottom: 20px;
				}
			}
		}

		&::before {
			@include snippet(pseudo-bg);
			background-color: rgba($black, 0.08);
			z-index: $layer-bg-overlay;
		}

		a {
			@include link-style {
				color: $white;
			}

			@include a11y-focus {
				outline: auto;
			}
		}
	}
}

.conflict-header {
	@include when-conflicts-intro {
		@include media-breakpoint-up(lg) {
			display: block;
		}
	}
}
