%base-font {
	@include font-size($font-size-base);
	line-height: $line-height-main;
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	letter-spacing: tracking(10);
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@extend %base-font;
}

p {
	font-family: inherit;
}

%body-copy,
.body-copy {
	@extend %base-font;

	&-small {
		@include font-size($font-size-sm);
		line-height: $line-height-xs;
	}

	&-medium {
		@include font-size($font-size-md);
		line-height: $line-height-sm;
	}
}

%h1-font {
	@include font-size($h1-font-size);
}
%h2-font {
	@include font-size($h2-font-size);
}
%h3-font {
	@include font-size($h3-font-size);
	line-height: $line-height-xs;
}
%h4-font {
	@include font-size($h4-font-size);
	line-height: $line-height-xs;
}
%h5-font {
	@include font-size($h5-font-size);
	line-height: $line-height-xs;
}
%h6-font {
	@include font-size($h6-font-size);
	line-height: $line-height-xs;
}

.h1,
h1 {
	@extend %h1-font;
}
.h2,
h2 {
	@extend %h2-font;
}
.h3,
h3 {
	@extend %h3-font;
}
.h4,
h4 {
	@extend %h4-font;
}
.h5,
h5 {
	@extend %h5-font;
}
.h6,
h6 {
	@extend %h6-font;
}

.font {
	&-xl {
		@include font-size($font-size-xl);
	}
	&-lg {
		@include font-size($font-size-lg);
	}
	&-md {
		@include font-size($font-size-md);
	}
	&-sm {
		@include font-size($font-size-sm);
	}
	&-xs {
		@include font-size($font-size-xs);
	}
}

%unlist {
	padding-left: 0;
	list-style-type: none;
}

%list-font {
	@include font-size($font-size-sm);
	line-height: $line-height-lg;
}

ul,
ol {
	@extend %list-font;

	&.simple-list {
		@extend %unlist;

		> li {
			display: block;
		}
	}

	&.inline-list {
		@extend %unlist;

		> li {
			display: inline;

			&:not(:last-child)::after {
				content: ", ";
			}
		}
	}
}

%inherit-text {
	letter-spacing: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}

pre {
	&.unformatted {
		white-space: pre-wrap;
		font-size: 12px;
	}
}

@include font-face("Oswald-Mono", "../fonts/Oswald-Mono", normal, normal, ("ttf"));

.dropcap {
	&,
	&-first > *:first-child {
		&::first-letter {
			$font-factor: 3.181818;
			$parent-em: 1em / $font-factor;
			font-size: percentage($font-factor);
			font-family: "Oswald-Mono";
			color: $linen;
			float: left;
			line-height: 1;
			padding: 0.294761em 0.475em 0.368094em;
			margin-left: -$parent-em;
			margin-right: $parent-em * 0.75;
			text-transform: uppercase;

			@supports (initial-letter: normal) {
				initial-letter: 3;
			}

			@include media-breakpoint-only(xs) {
				font-size: percentage($font-factor * 2 / 3);
				/* approximately increase padding down to bottom of 3rd line */
				padding-top: 0.294761em * 4 / 3;
				padding-bottom: 0.368094em * 4 / 3;

				@supports (initial-letter: normal) {
					initial-letter: 2;
				}
			}

			.green-bg & {
				background-color: $green-alt;
			}
			.navy-bg & {
				background-color: $navy-alt;
			}
			.charcoal-bg & {
				background-color: $charcoal-alt;
			}
			.brown-bg & {
				background-color: $brown-alt;
			}
		}
	}
}

%caption-font {
	@include font-size($font-size-xs);
	font-family: $font-family-sans;
	text-transform: uppercase;
	letter-spacing: tracking(35);

	// .hur & {
	// 	text-transform: none;
	// }
}

%btn-font {
	@extend %h6-font;
	font-family: $font-family-sans;
	font-weight: $font-weight-semi-bold;
	letter-spacing: tracking(25);
	text-transform: uppercase;
}

%blockquote-font,
blockquote {
	@include font-size($font-size-xl);
	line-height: $line-height-xs;
	letter-spacing: tracking(10);
}

blockquote {
	margin: ($line-height-xs * 1em) 0;
	margin-left: -0.75em;
	@include media-breakpoint-up(sm) {
		margin-left: -2.5em;
	}
}
