@mixin custom-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: $charcoal rgba($white, 0.1);

	&::-webkit-scrollbar {
		background-color: $charcoal;
		width: 0.5rem;
		height: 0.5rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba($white, 0.1);
		border-radius: $border-radius-pill;
	}
}

%custom-scrollbar {
	@include custom-scrollbar;
}
