@import url(../../../node_modules/simple-lightbox/dist/simpleLightbox.min.css);
/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, span.caption, figure, .figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Source Serif Pro", serif;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #081420; }
  @media (max-width: 1200px) {
    body {
      font-size: calc(1.0375rem + 0.45vw) ; } }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.25em; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure, .figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: grey;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.05rem + 0.6vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1170px; } }

.container-fluid {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
.aspect-wrapper > *, figure::before, .figure::before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.a11y-min-target-before::before, .a11y-min-target-after::after, .-faded::before, .-faded::after, .conflict-factions-vs::before, .conflict-factions-vs::after, #allies::before, #allies::after, .conflict-weapons::before, .conflict-weapons::after, .weapon-description::before, .weapon-description-content .h4::after, .home-broadcast::before {
  content: "";
  display: block; }
  .nav::before, .conflict-next::before, .conflict-next::after, .ssb-preview::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: -1; }
  .weapon-button .inline-svg::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 4; }

.btn.-circle, .pager-expanded > .conflict-header:not(:last-child),
body.conflict_template .conflict-header:not(:last-child), .conflict-gallery, .conflict .header-content-scroll, .footer-production-logos, .header:not(.pin), .header-content-scroll, .flex-center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center; }

.a11y-min-target-before::before, .a11y-min-target-after::after, .conflict-next-bg, .ssb-preview-bg, .header::before, .header-background, .header-particles {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */ }
  .sr-only:focus {
    background-color: whitesmoke;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: black;
    display: block;
    font-size: 0.05469rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

.semantic-only {
  margin-bottom: 0; }

*:focus,
button:focus {
  outline: 0; }

*.tab-focus:focus {
  outline: auto; }

.a11y-min-target-before::before, .a11y-min-target-after::after, .a11y-min-target {
  min-height: 40px;
  min-width: 40px; }

.a11y-min-target-before::before, .a11y-min-target-after::after {
  width: 100%;
  height: 100%;
  z-index: 1; }

.a11y-min-target {
  overflow: visible; }
  .a11y-min-target-before {
    overflow: visible; }
  .a11y-min-target-after {
    overflow: visible; }

@media screen and (-ms-high-contrast: active) {
  /* All high contrast styling rules */
  body {
    color: windowText;
    background: window; }
  a:link,
  a:visited {
    color: highlight;
    text-decoration: underline; }
    a:link:hover,
    a:visited:hover {
      background: highlight;
      color: windowText; }
  .sr-only.high-contrast {
    width: initial;
    position: initial !important;
    margin: initial;
    height: initial;
    clip-path: initial;
    clip: initial; } }

@media screen and (-ms-high-contrast: black-on-white) {
  /* Rules for users using black-on-white */ }

@media screen and (-ms-high-contrast: white-on-black) {
  /* Rules for users using white-on-black */ }

html.no-js .js-only,
html:not(.no-js) .no-js-only {
  display: none !important; }

@media screen and (prefers-reduced-motion: reduce) {
  #synopsis .dropcap-first > p:first-child,
  #synopsis .dropcap-first > p:first-child .figure, .location-blob, .weapon > figure, .weapon > .figure, .weapon-description, #home-logo, #title a svg,
  #title a img, .ssb-naw-presents, .ssb-title, .ssb-subtitle, .ssb-intro, .ssb-scroll {
    transition: none !important;
    animation: none !important; } }

.fade-in, .fade-in-each > *, .fade-in-with-delay {
  transition: opacity 1s ease; }
  .hide-js.fade-in, .fade-in-each > .hide-js, .hide-js.fade-in-with-delay {
    transition: none; }
    html:not(.no-js) .hide-js.fade-in, html:not(.no-js) .fade-in-each > .hide-js, html:not(.no-js) .hide-js.fade-in-with-delay {
      opacity: 0;
      pointer-events: none; }

.-delayed, .-delayed-x1 {
  transition-delay: 0.25s; }

.-delayed-x2 {
  transition-delay: 0.5s; }

.-delayed-x3 {
  transition-delay: 0.75s; }

.-delayed-x4 {
  transition-delay: 1s; }

@keyframes simpleFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.aspect-wrapper {
  position: relative;
  width: 100%; }
  .aspect-wrapper.aspect-21x9, .aspect-wrapper.aspect-ultrawide {
    padding-top: 42.85714%; }
  .aspect-wrapper.aspect-12x5, .aspect-wrapper.aspect-anamorphic {
    padding-top: 41.66667%; }
  .aspect-wrapper.aspect-16x9, .aspect-wrapper.aspect-hd {
    padding-top: 56.25%; }
  .aspect-wrapper.aspect-4x3, .aspect-wrapper.aspect-sd {
    padding-top: 75%; }
  .aspect-wrapper.aspect-1x1, .aspect-wrapper.aspect-square {
    padding-top: 100%; }


.clearfix:before,
.clear:before,
.clearfix:after,
.clear:after {
  content: " ";
  display: table; }


.clearfix,
.clear {
  *zoom: 1; }
  
  .clearfix:after,
  .clear:after {
    clear: both; }

@supports (position: sticky) {
  .pin, .pivotal-battle-bg, .home-header-background {
    position: sticky !important;
    top: 0; } }

@supports not (position: sticky) {
  .pin, .pivotal-battle-bg, .home-header-background {
    position: fixed !important; }
    .js-stuck.pin, .js-stuck.pivotal-battle-bg, .js-stuck.home-header-background {
      position: absolute !important;
      bottom: 0; }
    html.no-js .pin, html.no-js .pivotal-battle-bg, html.no-js .home-header-background {
      position: absolute !important; } }

@supports (position: sticky) {
  .pin-wrapper, .pivotal-battle-wrapper, .home-header-wrapper {
    overflow: visible;
    max-width: 100%; } }

.weapon-content-scroll {
  scrollbar-width: thin;
  scrollbar-color: #1c1c1d rgba(255, 255, 255, 0.1); }
  .weapon-content-scroll::-webkit-scrollbar {
    background-color: #1c1c1d;
    width: 0.5rem;
    height: 0.5rem; }
  .weapon-content-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 99999px; }

textarea {
  overflow: auto; }

picture {
  display: block; }

figure, .figure {
  margin-bottom: 0;
  position: relative;
  width: fit-content; }
  figure::before, .figure::before {
    content: "";
    display: block;
    background-color: inherit;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    z-index: 3; }
  .hover figure:hover::before, .hover .figure:hover::before,
  a.tab-focus:focus > figure::before,
  a.tab-focus:focus > .figure::before {
    opacity: 0.75; }
  .hover figure:hover figcaption, .hover .figure:hover figcaption, .hover figure:hover span.caption, .hover .figure:hover span.caption,
  a.tab-focus:focus > figure figcaption,
  a.tab-focus:focus > .figure figcaption,
  a.tab-focus:focus > figure span.caption,
  a.tab-focus:focus > .figure span.caption {
    opacity: 1; }

figcaption, span.caption {
  font-weight: 600;
  text-align: center;
  padding: 1em;
  margin: 0;
  position: absolute;
  z-index: 4;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease; }

img {
  max-width: 100%;
  height: auto; }


.cover-image-wrapper,
.cover-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  
  .cover-image-wrapper img {
    opacity: 0; }
  
  .cover-image-wrapper.align-left {
    background-position: left center; }
  
  .cover-image-wrapper.align-right {
    background-position: right center; }
  
  .cover-image-wrapper.align-top {
    background-position: center top; }
    
    .cover-image-wrapper.align-top-left {
      background-position: left top; }
    
    .cover-image-wrapper.align-top-right {
      background-position: right top; }
  
  .cover-image-wrapper.align-bottom {
    background-position: center bottom; }
    
    .cover-image-wrapper.align-bottom-left {
      background-position: left bottom; }
    
    .cover-image-wrapper.align-bottom-right {
      background-position: right bottom; }

.inline-image {
  float: left;
  margin-top: 0.25em;
  margin-right: 0.75em;
  margin-bottom: 0.2em; }
  @media (max-width: 767.98px) {
    .inline-image {
      margin: 1em 0 !important; }
      .inline-image-wrapper {
        float: left;
        display: flex;
        justify-content: center;
        width: 100%; } }

.inline-svg > div,
.inline-svg svg {
  max-width: 100%; }

.btn {
  -webkit-appearance: none;
  background: none;
  border: none;
  overflow: hidden;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  user-select: none; }

.btn:focus {
  box-shadow: none; }

.btn {
  color: inherit; }
  .btn:not(.-bare) {
    display: inline-block;
    padding: 1.33333em 1.45454em;
    border: 2px solid currentColor;
    border-radius: 99999px; }
    .btn:not(.-bare).tab-focus:focus, .btn:not(.-bare):hover {
      color: inherit;
      opacity: 0.5; }
  .btn.-narrow {
    padding: 1.33333em 1.25em; }
  .btn.-wide {
    padding: 1.33333em 2em; }
  .btn.-circle {
    min-width: 4.125em;
    min-height: 4.125em;
    max-width: 4.125em;
    max-height: 4.125em;
    white-space: nowrap;
    text-align: center; }
    .btn.-circle > .btn-icon {
      margin: 0; }
  .btn.-bare.tab-focus:focus, .btn.-bare:hover {
    outline: none; }
  .btn-icon {
    position: relative;
    vertical-align: middle;
    bottom: 0.1em;
    margin-right: 0.5em;
    font-size: inherit;
    height: 1.25em;
    width: auto; }
  .btn-list {
    padding: 0;
    margin: -5px;
    list-style: none;
    display: flex;
    flex-flow: row wrap; }
    .btn-list > .btn-list-item {
      margin: 5px; }

a img,
a .cover-image-wrapper {
  transition: opacity 0.3s ease;
  transition: opacity 0.3s ease, filter 0.3s ease;
  backface-visibility: hidden; }

a.tab-focus:focus, a:hover {
  text-decoration: none; }

body,
.body-copy {
  font-size: 1.375rem;
  line-height: 1.45454;
  font-family: "Source Serif Pro", serif;
  font-weight: 400;
  letter-spacing: 0.01em; }
  @media (max-width: 1200px) {
    body,
    .body-copy {
      font-size: calc(1.0375rem + 0.45vw) ; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p {
  font-family: inherit; }

.nav-lang a,
.body-copy-small {
  font-size: 1rem;
  line-height: 1.25; }


.body-copy-medium {
  font-size: 1.125rem;
  line-height: 1.33333; }
  @media (max-width: 1200px) {
    
    .body-copy-medium {
      font-size: calc(1.0125rem + 0.15vw) ; } }

.h1,
h1 {
  font-size: 4.375rem; }
  @media (max-width: 1200px) {
    .h1,
    h1 {
      font-size: calc(1.3375rem + 4.05vw) ; } }

.h2,
h2 {
  font-size: 3.125rem; }
  @media (max-width: 1200px) {
    .h2,
    h2 {
      font-size: calc(1.2125rem + 2.55vw) ; } }

.h3,
h3 {
  font-size: 1.875rem;
  line-height: 1.25; }
  @media (max-width: 1200px) {
    .h3,
    h3 {
      font-size: calc(1.0875rem + 1.05vw) ; } }

.h4,
h4, .section-title, .conflict-factions-vs::before, .conflict-factions-vs::after, .ssb-preview .ssb-subtitle {
  font-size: 1.5rem;
  line-height: 1.25; }
  @media (max-width: 1200px) {
    .h4,
    h4, .section-title, .conflict-factions-vs::before, .conflict-factions-vs::after, .ssb-preview .ssb-subtitle {
      font-size: calc(1.05rem + 0.6vw) ; } }

.h5,
h5, .ssb-naw-presents, .ssb-intro {
  font-size: 1.25rem;
  line-height: 1.25; }
  @media (max-width: 1200px) {
    .h5,
    h5, .ssb-naw-presents, .ssb-intro {
      font-size: calc(1.025rem + 0.3vw) ; } }

.h6,
h6, .btn, .conflict-header-explore {
  font-size: 1.125rem;
  line-height: 1.25; }
  @media (max-width: 1200px) {
    .h6,
    h6, .btn, .conflict-header-explore {
      font-size: calc(1.0125rem + 0.15vw) ; } }

.font-xl {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    .font-xl {
      font-size: calc(1.075rem + 0.9vw) ; } }

.font-lg {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    .font-lg {
      font-size: calc(1.05rem + 0.6vw) ; } }

.font-md {
  font-size: 1.125rem; }
  @media (max-width: 1200px) {
    .font-md {
      font-size: calc(1.0125rem + 0.15vw) ; } }

.font-sm {
  font-size: 1rem; }

.font-xs {
  font-size: 0.875rem; }

ul.simple-list,
ol.simple-list, ul.inline-list,
ol.inline-list, .social-icons {
  padding-left: 0;
  list-style-type: none; }

ul,
ol {
  font-size: 1rem;
  line-height: 1.55556; }

ul.simple-list > li,
ol.simple-list > li {
  display: block; }

ul.inline-list > li,
ol.inline-list > li {
  display: inline; }
  ul.inline-list > li:not(:last-child)::after,
  ol.inline-list > li:not(:last-child)::after {
    content: ", "; }

.semantic-only {
  letter-spacing: inherit;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

pre.unformatted {
  white-space: pre-wrap;
  font-size: 12px; }

@font-face {
  font-family: "Oswald-Mono";
  src: url("./../fonts//../fonts/Oswald-Mono.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.dropcap::first-letter,
.dropcap-first > *:first-child::first-letter {
  font-size: 318.1818%;
  font-family: "Oswald-Mono";
  color: #e9ead9;
  float: left;
  line-height: 1;
  padding: 0.294761em 0.475em 0.368094em;
  margin-left: -0.31429em;
  margin-right: 0.23571em;
  text-transform: uppercase; }
  @supports (initial-letter: normal) {
    .dropcap::first-letter,
    .dropcap-first > *:first-child::first-letter {
      initial-letter: 3; } }
  @media (max-width: 575.98px) {
    .dropcap::first-letter,
    .dropcap-first > *:first-child::first-letter {
      font-size: 212.1212%;
      /* approximately increase padding down to bottom of 3rd line */
      padding-top: 0.39301em;
      padding-bottom: 0.49079em; }
      @supports (initial-letter: normal) {
        .dropcap::first-letter,
        .dropcap-first > *:first-child::first-letter {
          initial-letter: 2; } } }
  .green-bg .dropcap::first-letter, .green-bg
  .dropcap-first > *:first-child::first-letter {
    background-color: #394531; }
  .navy-bg .dropcap::first-letter, .navy-bg
  .dropcap-first > *:first-child::first-letter {
    background-color: #2f3642; }
  .charcoal-bg .dropcap::first-letter, .charcoal-bg
  .dropcap-first > *:first-child::first-letter {
    background-color: #48464a; }
  .brown-bg .dropcap::first-letter, .brown-bg
  .dropcap-first > *:first-child::first-letter {
    background-color: #42392f; }

figcaption, span.caption, img, .slbCaption, .slbCloseBtn, .ssb-preview .ssb-naw-presents {
  font-size: 0.875rem;
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.035em; }

.btn, .conflict-header-explore {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase; }


blockquote {
  font-size: 1.75rem;
  line-height: 1.25;
  letter-spacing: 0.01em; }
  @media (max-width: 1200px) {
    
    blockquote {
      font-size: calc(1.075rem + 0.9vw) ; } }

blockquote {
  margin: 1.25em 0;
  margin-left: -0.75em; }
  @media (min-width: 576px) {
    blockquote {
      margin-left: -2.5em; } }

.-center.social-icons,
.row.-center {
  justify-content: center; }

.-spread.social-icons,
.row.-spread {
  justify-content: space-between; }

.-distribute.social-icons,
.row.-distribute {
  justify-content: space-evenly; }

.-equal.social-icons,
.row.-equal {
  justify-content: space-around; }

.row > br {
  content: "";
  display: block;
  flex-basis: 100%;
  width: 100%;
  height: 0;
  margin: 0;
  border: 0; }

.inset-0 {
  margin-left: 0%; }

.inset-next-0 {
  margin-right: 0%; }

.inset-1 {
  margin-left: -8.33333%; }

.inset-next-1 {
  margin-right: -8.33333%; }

.inset-2 {
  margin-left: -16.66667%; }

.inset-next-2 {
  margin-right: -16.66667%; }

.inset-3 {
  margin-left: -25%; }

.inset-next-3 {
  margin-right: -25%; }

.inset-4 {
  margin-left: -33.33333%; }

.inset-next-4 {
  margin-right: -33.33333%; }

.inset-5 {
  margin-left: -41.66667%; }

.inset-next-5 {
  margin-right: -41.66667%; }

.inset-6 {
  margin-left: -50%; }

.inset-next-6 {
  margin-right: -50%; }

.offset-half {
  margin-left: 4.16667%; }

@media (min-width: 576px) {
  .inset-sm-0 {
    margin-left: 0%; }
  .inset-next-sm-0 {
    margin-right: 0%; }
  .inset-sm-1 {
    margin-left: -8.33333%; }
  .inset-next-sm-1 {
    margin-right: -8.33333%; }
  .inset-sm-2 {
    margin-left: -16.66667%; }
  .inset-next-sm-2 {
    margin-right: -16.66667%; }
  .inset-sm-3 {
    margin-left: -25%; }
  .inset-next-sm-3 {
    margin-right: -25%; }
  .inset-sm-4 {
    margin-left: -33.33333%; }
  .inset-next-sm-4 {
    margin-right: -33.33333%; }
  .inset-sm-5 {
    margin-left: -41.66667%; }
  .inset-next-sm-5 {
    margin-right: -41.66667%; }
  .inset-sm-6 {
    margin-left: -50%; }
  .inset-next-sm-6 {
    margin-right: -50%; }
  .offset-sm-half {
    margin-left: 4.16667%; } }

@media (min-width: 768px) {
  .inset-md-0 {
    margin-left: 0%; }
  .inset-next-md-0 {
    margin-right: 0%; }
  .inset-md-1 {
    margin-left: -8.33333%; }
  .inset-next-md-1 {
    margin-right: -8.33333%; }
  .inset-md-2 {
    margin-left: -16.66667%; }
  .inset-next-md-2 {
    margin-right: -16.66667%; }
  .inset-md-3 {
    margin-left: -25%; }
  .inset-next-md-3 {
    margin-right: -25%; }
  .inset-md-4 {
    margin-left: -33.33333%; }
  .inset-next-md-4 {
    margin-right: -33.33333%; }
  .inset-md-5 {
    margin-left: -41.66667%; }
  .inset-next-md-5 {
    margin-right: -41.66667%; }
  .inset-md-6 {
    margin-left: -50%; }
  .inset-next-md-6 {
    margin-right: -50%; }
  .offset-md-half {
    margin-left: 4.16667%; } }

@media (min-width: 992px) {
  .inset-lg-0 {
    margin-left: 0%; }
  .inset-next-lg-0 {
    margin-right: 0%; }
  .inset-lg-1 {
    margin-left: -8.33333%; }
  .inset-next-lg-1 {
    margin-right: -8.33333%; }
  .inset-lg-2 {
    margin-left: -16.66667%; }
  .inset-next-lg-2 {
    margin-right: -16.66667%; }
  .inset-lg-3 {
    margin-left: -25%; }
  .inset-next-lg-3 {
    margin-right: -25%; }
  .inset-lg-4 {
    margin-left: -33.33333%; }
  .inset-next-lg-4 {
    margin-right: -33.33333%; }
  .inset-lg-5 {
    margin-left: -41.66667%; }
  .inset-next-lg-5 {
    margin-right: -41.66667%; }
  .inset-lg-6 {
    margin-left: -50%; }
  .inset-next-lg-6 {
    margin-right: -50%; }
  .offset-lg-half {
    margin-left: 4.16667%; } }

@media (min-width: 1280px) {
  .inset-xl-0 {
    margin-left: 0%; }
  .inset-next-xl-0 {
    margin-right: 0%; }
  .inset-xl-1 {
    margin-left: -8.33333%; }
  .inset-next-xl-1 {
    margin-right: -8.33333%; }
  .inset-xl-2 {
    margin-left: -16.66667%; }
  .inset-next-xl-2 {
    margin-right: -16.66667%; }
  .inset-xl-3 {
    margin-left: -25%; }
  .inset-next-xl-3 {
    margin-right: -25%; }
  .inset-xl-4 {
    margin-left: -33.33333%; }
  .inset-next-xl-4 {
    margin-right: -33.33333%; }
  .inset-xl-5 {
    margin-left: -41.66667%; }
  .inset-next-xl-5 {
    margin-right: -41.66667%; }
  .inset-xl-6 {
    margin-left: -50%; }
  .inset-next-xl-6 {
    margin-right: -50%; }
  .offset-xl-half {
    margin-left: 4.16667%; } }

.slbElement {
  font-size: 1rem;
  color: #e9ead9; }

.slbCaption {
  height: 5em;
  white-space: normal;
  text-overflow: ellipsis;
  color: rgba(255, 255, 255, 0.9);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #1c1c1d rgba(255, 255, 255, 0.1); }
  .slbCaption::-webkit-scrollbar {
    background-color: #1c1c1d;
    width: 0.5rem;
    height: 0.5rem; }
  .slbCaption::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 99999px; }

.slbCloseBtn {
  width: auto;
  height: auto;
  color: #e9ead9;
  right: calc(1rem - 5px);
  top: calc(4em - 29px);
  transition: opacity 200ms ease; }
  .slbCloseBtn:not(.tab-focus):focus {
    outline: none; }
  .slbCloseBtn img {
    display: block; }

.slbArrow {
  opacity: 1; }
  .slbArrow::before {
    opacity: 1;
    transition: opacity 0.3s ease;
    background: url("./../media//arrow-circle.svg") center/cover no-repeat;
    border: none;
    margin: 0;
    width: 3rem;
    height: 3rem; }
  .slbArrow::after {
    transition: opacity 0.3s ease;
    opacity: 0;
    content: "";
    display: block;
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle closest-side, rgba(233, 234, 217, 0.5), rgba(233, 234, 217, 0)); }
  .slbArrow.prev::before {
    transform: translate(-50%, -50%) rotate(90deg); }
  .slbArrow.next::before {
    transform: translate(-50%, -50%) rotate(-90deg); }
  .slbArrow:hover {
    opacity: 1; }
  .hover .slbArrow:hover::before, .slbArrow.tab-focus:focus::before {
    opacity: 0.5; }
  .hover .slbArrow:hover::after, .slbArrow.tab-focus:focus::after {
    opacity: 1; }

.slbOverlay {
  opacity: 0.9; }

@keyframes slbOverlay {
  from {
    opacity: 0; }
  to {
    opacity: 0.9; } }

@media (max-width: 767.98px) {
  .slbArrows {
    bottom: 0;
    top: unset; }
  .slbArrow {
    bottom: 0;
    top: unset;
    height: 5em; }
  .slbCaption {
    max-width: calc(100vw - 12em);
    margin: 0 auto;
    scrollbar-width: thin;
    scrollbar-color: #1c1c1d rgba(255, 255, 255, 0.1); }
    .slbCaption::-webkit-scrollbar {
      background-color: #1c1c1d;
      width: 0.5rem;
      height: 0.5rem; }
    .slbCaption::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 99999px; } }

.nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  z-index: 25;
  font-family: "Oswald", "Source Sans Pro", sans-serif;
  padding: 1em 1.45454em;
  transition: background-color 300ms ease, opacity 300ms ease, transform 300ms ease; }
  html:not(.no-js) .nav.nav-hide-js {
    opacity: 0;
    transform: translateY(-101%); }
  html.no-js .nav {
    background: #000; }
  .nav::before {
    transition: opacity 300ms ease;
    background-color: inherit;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.6); }
  html:not(.no-js) .nav:not(.nav-fill),
  html:not(.no-js) .nav:not(.nav-fill) .row,
  html:not(.no-js) .nav:not(.nav-fill) .nav-content {
    pointer-events: none; }
  html:not(.no-js) .nav:not(.nav-fill) .nav-home, html:not(.no-js) .nav:not(.nav-fill) .nav-social, html:not(.no-js) .nav:not(.nav-fill) .nav-lang, html:not(.no-js) .nav:not(.nav-fill) .nav-close {
    pointer-events: auto !important; }
  html:not(.no-js) .nav:not(.nav-fill)::before {
    opacity: 0; }
  .nav > .row {
    font-size: 0.875rem;
    align-items: center; }
  .nav-home {
    margin-right: 1em; }
  .nav-content .row {
    align-items: center; }
  .nav-heading {
    margin-right: 1em;
    transition: opacity 300ms ease; }
    .nav-heading > h1 {
      padding-bottom: 0.01em; }
      @media (min-width: 1280px) {
        .nav-heading > h1 br {
          display: none; } }
    .nav-heading:empty {
      display: none; }
  .nav-body {
    font-size: inherit;
    display: flex;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
    white-space: nowrap; }
    html:not(.no-js) .nav:not(.nav-fill) .nav-body {
      pointer-events: none;
      opacity: 0; }
    .nav-body > li {
      margin: 0 0.725vw;
      display: block; }
      @media (min-width: 1440px) {
        .nav-body > li {
          margin: 0 0.75em; } }
    .nav-body:empty {
      display: none; }
  .nav-anchor,
  .nav-mobile > select {
    color: #e9ead9;
    text-transform: uppercase;
    letter-spacing: 0.035em; }
  .nav-anchor {
    opacity: 0.6;
    transition: opacity 300ms ease; }
    .nav-anchor.tab-focus:focus, .nav-anchor:hover {
      color: #e9ead9;
      text-decoration: underline;
      opacity: 1; }
    .nav-anchor.active {
      text-decoration: underline;
      opacity: 1; }
      ul:hover .nav-anchor.active:not(:hover) {
        opacity: 0.6; }
  @media (max-width: 359.98px) {
    .nav-social {
      display: none; } }
  .nav .social-icons img {
    display: block; }
  .nav-lang {
    padding-left: 1em;
    padding-right: 1em; }
    .nav-lang a {
      letter-spacing: 0.025em;
      transition: font-size 300ms ease 200ms; }
      .cancelable .nav-lang a {
        transition-delay: 0s !important;
        font-size: 0.75rem; }
  .nav-close {
    color: #e9ead9;
    max-width: 0;
    margin-left: 0;
    margin-right: -10px;
    opacity: 0;
    visibility: hidden;
    transition: max-width 300ms ease, margin-left 300ms ease, margin-right 300ms ease, opacity 200ms ease, visibility 0s 301ms; }
    .cancelable .nav-close {
      visibility: visible;
      transition: max-width 300ms ease, margin-left 300ms ease, margin-right 300ms ease, opacity 200ms ease;
      transition-delay: 200ms;
      max-width: 2.8em;
      margin-left: -0.5em;
      margin-right: 0;
      opacity: 1; }
    .nav-close > a {
      color: inherit; }
    .nav-close svg {
      color: currentColor; }
    .nav-close.tab-focus:focus, .nav-close:hover {
      opacity: 0.75; }
  .nav-trigger {
    display: flex;
    height: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
    .header ~ .nav-trigger,
    .home-header-wrapper ~ .nav-trigger {
      margin-top: -80px;
      margin-bottom: 80px; }
  html:not(.no-js) .nav-hide-js {
    opacity: 0;
    pointer-events: none; }
  .nav-mobile {
    display: none;
    transition: opacity 300ms ease; }
    @media (max-width: 575.98px) {
      .nav-mobile {
        transition: opacity 300ms ease, margin-top 300ms ease; }
        .nav-mobile.nav-hide-js {
          margin-top: -2.5em; } }
    @media (max-width: 1023.98px) {
      .nav-mobile {
        display: block; }
        .nav-body {
          display: none; } }
    .nav-mobile select {
      cursor: pointer;
      display: block;
      padding: 0.5em;
      padding-right: 2em;
      border: none;
      -webkit-appearance: none;
      background: url("./../media//angle.svg") right center/1em auto no-repeat; }
      .nav-mobile select:active, .nav-mobile select:focus-within, .nav-mobile select:focus, .nav-mobile select:hover {
        text-decoration: underline; }
        .nav-mobile select:active option,
        .nav-mobile select:active optgroup, .nav-mobile select:focus-within option,
        .nav-mobile select:focus-within optgroup, .nav-mobile select:focus option,
        .nav-mobile select:focus optgroup, .nav-mobile select:hover option,
        .nav-mobile select:hover optgroup {
          color: black;
          background-color: white; }
        .nav-mobile select:active option:checked, .nav-mobile select:focus-within option:checked, .nav-mobile select:focus option:checked, .nav-mobile select:hover option:checked {
          background: linen; }
      .nav-mobile select:empty {
        display: none; }
      @media (max-width: 767.98px) {
        @supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
          .nav-mobile select {
            font-size: 1rem !important; } } }
  @media (max-width: 767.98px) {
    .nav-lang a {
      font-size: 0.75rem; }
    .nav-heading {
      display: none; }
    .home .nav-body, .home .nav-close {
      display: none; }
    .nav-content {
      margin-right: auto; }
    .nav-social {
      margin-left: auto;
      margin-right: auto; }
    .nav-lang {
      margin-left: auto; }
    .nav.cancelable .nav-social {
      display: none; } }
  @media (max-width: 575.98px) {
    .nav br {
      order: 3; }
    .nav-content {
      margin: 0 auto;
      order: 4;
      margin-top: 0.5em; }
    .nav-home {
      margin-right: auto; }
    .nav-mobile {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center; } }

.pager-deck {
  position: relative;
  min-height: 100vh; }
  .pager-deck:not(.has-pager-expanded) {
    overflow: hidden; }
  html.no-js .pager-deck.has-pager-expanded .pager-panel:not(.pager-expanded) {
    display: none; }
  @media (max-width: 991.98px) {
    .pager-deck.has-pager-expanded .pager-panel:not(.pager-expanded) {
      display: none; } }
  @media screen and (prefers-reduced-motion: reduce) {
    .pager-deck.has-pager-expanded .pager-panel:not(.pager-expanded) {
      display: none; } }

.pager-panel {
  position: relative;
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  @media (min-width: 992px) {
    html:not(.no-js) .pager-panel {
      position: absolute;
      top: 0;
      left: 0;
      perspective: 1800px;
      opacity: 0;
      visibility: hidden; }
      html:not(.no-js) .pager-panel:not(:first-child) {
        perspective-origin: center 0; }
      html:not(.no-js) .pager-panel:not(.pager-expanded) {
        max-height: 100%; } }
  @media (min-width: 992px) {
    html:not(.no-js) .has-pager-expanded > .pager-panel:not(.pager-expanded) {
      display: none; } }

.pager-panel-body {
  position: relative;
  width: 100%;
  min-height: inherit;
  overflow: hidden;
  visibility: visible;
  transition: min-height 0.5s ease, padding 0.5s ease; }
  @media (min-width: 576px) {
    .pager-panel-body {
      padding-left: 4rem;
      padding-right: 4rem; } }
  @media (min-width: 992px) {
    html:not(.no-js) .pager-panel-body {
      /* Force Hardware Acceleration */
      transform: translateZ(0);
      backface-visibility: hidden;
      transform-origin: center bottom; } }
  html.no-js .pager-panel-body {
    transform: none !important; }
  @media (max-width: 991.98px) {
    .pager-panel-body {
      transform: none !important; } }
  @media screen and (prefers-reduced-motion: reduce) {
    .pager-panel-body {
      transform: none !important; } }

.pager-nav, .slider-nav {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  bottom: auto;
  transform: translate(-2.25rem, -50%);
  display: none;
  flex-flow: column;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.2;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s 0s, visibility 0s 0.2s; }
  @media (max-width: 1200px) {
    .pager-nav, .slider-nav {
      font-size: calc(1.0125rem + 0.15vw) ; } }
  @media (min-width: 992px) {
    html:not(.no-js) .pager-nav, html:not(.no-js) .slider-nav {
      display: flex; } }
  @supports (-ms-ime-align: auto) {
    .pager-expanded ~ .pager-nav, .pager-expanded ~ .slider-nav {
      display: none !important; } }
  #conflicts-intro.pager-visible ~ .pager-nav, #conflicts-intro.pager-visible ~ .slider-nav {
    opacity: 0;
    visibility: hidden;
    transition: none; }

.pager-prev, .slider-prev, .pager-next, .slider-next {
  display: block;
  position: relative;
  overflow: visible;
  transition: opacity 0.2s 0s; }
  .pager-prev img, .slider-prev img, .pager-next img, .slider-next img {
    transition: opacity 0.3s ease; }
  .pager-prev.tab-focus:focus:not([class*="inactive"])::before, .tab-focus.slider-prev:focus:not([class*="inactive"])::before, .pager-next.tab-focus:focus:not([class*="inactive"])::before, .tab-focus.slider-next:focus:not([class*="inactive"])::before, .pager-prev:hover:not([class*="inactive"])::before, .slider-prev:hover:not([class*="inactive"])::before, .pager-next:hover:not([class*="inactive"])::before, .slider-next:hover:not([class*="inactive"])::before {
    opacity: 1; }
  .pager-prev.tab-focus:focus:not([class*="inactive"]) img, .tab-focus.slider-prev:focus:not([class*="inactive"]) img, .pager-next.tab-focus:focus:not([class*="inactive"]) img, .tab-focus.slider-next:focus:not([class*="inactive"]) img, .pager-prev:hover:not([class*="inactive"]) img, .slider-prev:hover:not([class*="inactive"]) img, .pager-next:hover:not([class*="inactive"]) img, .slider-next:hover:not([class*="inactive"]) img {
    opacity: 0.5; }
  .pager-prev::before, .slider-prev::before, .pager-next::before, .slider-next::before {
    background: radial-gradient(circle closest-side, rgba(233, 234, 217, 0.25), rgba(233, 234, 217, 0));
    opacity: 0;
    transition: opacity 0.5s ease; }

.pager-prev, .slider-prev {
  transform: rotate(180deg); }

.pager-inactive, .slider-inactive {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s 0s, visibility 0s 0.2s; }

.pager-meta, .slider-meta {
  margin: 1.33333em 0;
  display: flex;
  flex-flow: column; }

.pager-current, .slider-current, .pager-sep, .slider-sep, .pager-total, .slider-total {
  display: block; }

.pager-sep, .slider-sep {
  width: 0.95em;
  border-bottom: 2px solid currentColor;
  margin: 0.33333em auto; }

.pager-expanded.pager-panel {
  min-height: unset;
  overflow: unset;
  visibility: visible !important;
  z-index: 2; }

.pager-expanded ~ .pager-nav, .pager-expanded ~ .slider-nav {
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }

main {
  counter-reset: section; }

@counter-style section {}.-faded {
  position: relative; }
  .-faded::before, .-faded::after {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 0;
    pointer-events: none;
    padding-top: 25%; }
  .-faded::after {
    bottom: -1px; }
  .green-bg .-faded::before {
    top: -1px;
    background: linear-gradient(to bottom, #122209, rgba(18, 34, 9, 0)); }
  .green-bg .-faded::after {
    bottom: -1px;
    background: linear-gradient(to top, #122209, rgba(18, 34, 9, 0)); }
  .navy-bg .-faded::before {
    top: -1px;
    background: linear-gradient(to bottom, #081420, rgba(8, 20, 32, 0)); }
  .navy-bg .-faded::after {
    bottom: -1px;
    background: linear-gradient(to top, #081420, rgba(8, 20, 32, 0)); }
  .charcoal-bg .-faded::before {
    top: -1px;
    background: linear-gradient(to bottom, #1c1c1d, rgba(28, 28, 29, 0)); }
  .charcoal-bg .-faded::after {
    bottom: -1px;
    background: linear-gradient(to top, #1c1c1d, rgba(28, 28, 29, 0)); }
  .brown-bg .-faded::before {
    top: -1px;
    background: linear-gradient(to bottom, #201408, rgba(32, 20, 8, 0)); }
  .brown-bg .-faded::after {
    bottom: -1px;
    background: linear-gradient(to top, #201408, rgba(32, 20, 8, 0)); }

.section {
  padding-top: 2em;
  padding-bottom: 2em; }
  .section-copy {
    padding-left: 10px; }
    @media (max-width: 575.98px) {
      .section-copy {
        padding-right: 10px; } }
  .section.-numbered {
    counter-increment: section; }
  .section-title {
    text-transform: uppercase;
    font-family: "Oswald", "Source Sans Pro", sans-serif;
    font-weight: 700;
    color: #e9ead9;
    opacity: 0.5;
    position: relative;
    margin-top: 2em;
    margin-bottom: 2em; }
    .section.-numbered .section-title::before {
      content: "0" counter(section) !important;
      font-size: 4rem;
      line-height: 1;
      opacity: 0.6;
      position: absolute; }
      @media (max-width: 1200px) {
        .section.-numbered .section-title::before {
          font-size: calc(1.3rem + 3.6vw) ; } }
      @media (max-width: 575.98px) {
        .section.-numbered .section-title::before {
          bottom: calc(100% + 0.25em); } }
      @media (min-width: 576px) {
        .section.-numbered .section-title::before {
          bottom: 0;
          transform: translate(-100%, 0);
          left: -0.375em; } }
      @media (min-width: 768px) {
        .section.-numbered .section-title::before {
          left: -0.875em; } }
      @media (min-width: 992px) {
        .section.-numbered .section-title::before {
          left: -1.175em; } }

.social-icons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: -0.5rem; }
  .social-icons li {
    display: block;
    padding: 0.5rem; }

@media (min-width: 992px) {
  html:not(.no-js) .conflict-trigger.pager-hide-js .conflict-header-title, .conflict-trigger.pager-hide-js html:not(.no-js) .conflict-header-title, html:not(.no-js) .conflict-trigger.pager-hide-js .conflict-header-dates, .conflict-trigger.pager-hide-js html:not(.no-js) .conflict-header-dates, html:not(.no-js) .conflict-trigger.pager-hide-js .conflict-header-explore, .conflict-trigger.pager-hide-js html:not(.no-js) .conflict-header-explore {
    opacity: 0; } }

.conflict-trigger {
  position: relative;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }

.conflict-header-title {
  color: #e9ead9;
  margin: 0 auto 0.17857em;
  line-height: 1.125; }
  .conflict-trigger:not(.pager-hide-js) .conflict-header-title {
    animation: simpleFadeIn 0.5s ease-out backwards; }

.conflict-header-dates {
  margin: -0.25rem auto 0; }
  .conflict-trigger:not(.pager-hide-js) .conflict-header-dates {
    animation: simpleFadeIn 0.5s 0.25s ease-out backwards; }

.conflict-header-explore {
  position: absolute;
  left: 50%;
  padding-top: 1em;
  transform: translate(-50%);
  min-width: 7em; }
  .conflict-trigger:not(.pager-hide-js) .conflict-header-explore {
    animation: simpleFadeIn 0.5s 0.5s ease-out backwards; }
  .conflict-header-explore img {
    margin-inline-end: 0.25em;
    vertical-align: middle;
    position: relative;
    bottom: 0.1em; }
  div.conflict-trigger .conflict-header-explore {
    display: none; }

.pager-expanded > .conflict-header:not(:last-child),
body.conflict_template .conflict-header:not(:last-child) {
  min-height: 5em; }
  .pager-expanded > .conflict-header:not(:last-child) .header-content-scroll,
  body.conflict_template .conflict-header:not(:last-child) .header-content-scroll {
    position: relative;
    overflow: visible; }

.pager-expanded > .conflict-header .conflict-header-explore,
body.conflict_template .conflict-header-explore {
  opacity: 0; }

.pager-expanded > .conflict-header .conflict-header-dates,
body.conflict_template .conflict-header-dates {
  font-size: 2.1875rem; }
  @media (max-width: 1200px) {
    .pager-expanded > .conflict-header .conflict-header-dates,
    body.conflict_template .conflict-header-dates {
      font-size: calc(1.11875rem + 1.425vw) ; } }

@supports (width: fit-content) {
  .conflict-header-content a {
    margin: 0 auto; }
  .conflict-header-content a, .conflict-header-title, .conflict-header-dates, .conflict-header-explore {
    width: fit-content;
    display: block; } }

.conflict-header-title, .conflict-header-dates, .conflict-header-explore {
  opacity: inherit; }

.conflict-main {
  width: 100%; }

.conflict-gallery {
  margin-top: calc(2.5em - 6px);
  margin-bottom: calc(2.5em - 6px);
  flex-wrap: wrap; }
  @media (min-width: 1280px) {
    .conflict-gallery {
      margin-left: calc(-2em - 6px);
      margin-right: calc(-2em - 6px); } }
  .conflict-gallery > a {
    /* Use a:nth-of-type() to style specific images */
    color: inherit;
    float: left; }
  .conflict-gallery > hr {
    /* Use a:nth-of-type() to style specific images */
    pointer-events: none;
    border: none;
    opacity: 0;
    visibility: hidden;
    flex: 1 0 100%;
    display: none;
    margin: 0;
    padding: 0; }
  .conflict-gallery figure, .conflict-gallery .figure {
    margin: 6px; }

#synopsis {
  background: url("./../media//triangle-fade-edge.svg") left top/50% auto no-repeat; }
  #synopsis .dropcap-first > p:first-child {
    transition: none;
    pointer-events: auto;
    animation: simpleFadeIn 1s ease 0.5s 1 normal both; }
    #synopsis .dropcap-first > p:first-child .figure {
      animation: slideFade 0.75s ease 0.75s 1 normal both; }

@keyframes slideFade {
  from {
    transform: translateY(5%);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }
      .hide-js #synopsis .dropcap-first > p:first-child .figure {
        animation-play-state: paused; }
  @media (max-width: 767.98px) {
    #synopsis a figcaption, #synopsis a span.caption {
      display: none; } }

#location {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center; }

@media (orientation: portrait) and (max-width: 767.98px) {
  #location {
    background-size: 150% auto; } }
  #location::before, #location::after {
    padding-top: 10%; }
  #location .container {
    z-index: 1;
    position: relative; }
  .location-map {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
    .location-map img {
      max-width: 100%;
      width: auto; }
  .location-blob {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    animation: blobPulse 1.25s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate; }

@keyframes blobPulse {
  from {
    opacity: 0.5; }
  to {
    opacity: 1; } }
    @supports (filter: contrast()) {
      .location-blob {
        filter: contrast(1.25); } }

.conflict .conflict-category .inline-svg {
  max-width: 100%;
  height: auto; }

.conflict .conflict-category text {
  fill: #e9ead9;
  text-transform: uppercase; }

.conflict .conflict-category .categories {
  opacity: 0.8;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 11px;
  letter-spacing: 0.025em;
  font-weight: 600; }

.conflict .conflict-category.show-active .cat {
  transition-property: fill, opacity, font-size;
  transition-duration: 1.25s;
  transition-timing-function: ease; }

.conflict .conflict-category.show-active.cat-1-active .cat-1 {
  fill: red;
  font-weight: 700;
  font-size: 12px;
  opacity: 1;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }

.conflict .conflict-category.show-active.cat-2-active .cat-2 {
  fill: red;
  font-weight: 700;
  font-size: 12px;
  opacity: 1;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }

.conflict .conflict-category.show-active.cat-3-active .cat-3 {
  fill: red;
  font-weight: 700;
  font-size: 12px;
  opacity: 1;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }

.conflict .conflict-category.show-active.cat-4-active .cat-4 {
  fill: red;
  font-weight: 700;
  font-size: 12px;
  opacity: 1;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }

.conflict .conflict-category.show-active.cat-5-active .cat-5 {
  fill: red;
  font-weight: 700;
  font-size: 12px;
  opacity: 1;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }

.conflict-factions {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  z-index: 1; }

@media (orientation: portrait) and (max-width: 767.98px) {
  .conflict-factions {
    background-size: 200% auto; } }
  .conflict-factions-vs {
    text-align: center;
    align-self: flex-start;
    font-size: 1.3125rem;
    line-height: 2.19048;
    width: 2.19048em;
    border-radius: 50%; }
    @media (max-width: 1200px) {
      .conflict-factions-vs {
        font-size: calc(1.03125rem + 0.375vw) ; } }
    @media (max-width: 991.98px) {
      .conflict-factions-vs {
        margin: 1.5em; } }
    .primary-bg .conflict-factions-vs {
      background: #56a4f1; }
      .primary-bg .conflict-factions-vs::before, .primary-bg .conflict-factions-vs::after {
        color: #56a4f1; }
    .secondary-bg .conflict-factions-vs {
      background: #9aa09f; }
      .secondary-bg .conflict-factions-vs::before, .secondary-bg .conflict-factions-vs::after {
        color: #9aa09f; }
    .success-bg .conflict-factions-vs {
      background: #626c56; }
      .success-bg .conflict-factions-vs::before, .success-bg .conflict-factions-vs::after {
        color: #626c56; }
    .info-bg .conflict-factions-vs {
      background: #65bdc4; }
      .info-bg .conflict-factions-vs::before, .info-bg .conflict-factions-vs::after {
        color: #65bdc4; }
    .warning-bg .conflict-factions-vs {
      background: #f7d055; }
      .warning-bg .conflict-factions-vs::before, .warning-bg .conflict-factions-vs::after {
        color: #f7d055; }
    .danger-bg .conflict-factions-vs {
      background: #e1787c; }
      .danger-bg .conflict-factions-vs::before, .danger-bg .conflict-factions-vs::after {
        color: #e1787c; }
    .light-bg .conflict-factions-vs {
      background: #f2f3ee; }
      .light-bg .conflict-factions-vs::before, .light-bg .conflict-factions-vs::after {
        color: #f2f3ee; }
    .dark-bg .conflict-factions-vs {
      background: #777b79; }
      .dark-bg .conflict-factions-vs::before, .dark-bg .conflict-factions-vs::after {
        color: #777b79; }
    .navy-bg .conflict-factions-vs {
      background: #5b6364; }
      .navy-bg .conflict-factions-vs::before, .navy-bg .conflict-factions-vs::after {
        color: #5b6364; }
    .brown-bg .conflict-factions-vs {
      background: #6a6355; }
      .brown-bg .conflict-factions-vs::before, .brown-bg .conflict-factions-vs::after {
        color: #6a6355; }
    .charcoal-bg .conflict-factions-vs {
      background: #686863; }
      .charcoal-bg .conflict-factions-vs::before, .charcoal-bg .conflict-factions-vs::after {
        color: #686863; }
    .green-bg .conflict-factions-vs {
      background: #626c56; }
      .green-bg .conflict-factions-vs::before, .green-bg .conflict-factions-vs::after {
        color: #626c56; }
    .conflict-factions-vs::before, .conflict-factions-vs::after {
      display: block;
      position: absolute;
      border: 0 solid currentColor; }
      @media (max-width: 767.98px) {
        .col-md-2 > .conflict-factions-vs::before, .col-md-2 > .conflict-factions-vs::after {
          font-size: inherit !important;
          border-top-width: 2px;
          top: 50%;
          width: calc(50% - 1.09524em - 15px); } }
      @media (max-width: 991.98px) {
        .col-lg-1 > .conflict-factions-vs::before, .col-lg-1 > .conflict-factions-vs::after {
          font-size: inherit !important;
          border-top-width: 2px;
          top: 50%;
          width: calc(50% - 1.09524em - 15px); } }
    .conflict-factions-vs::after {
      left: 50%; }
      @media (max-width: 767.98px) {
        .col-md-2 > .conflict-factions-vs::after {
          transform: translate(calc(1.09524em), -50%); } }
      @media (min-width: 768px) {
        .col-md-2 > .conflict-factions-vs::after {
          border-left-width: 2px;
          height: calc(100% + 2.625em + 1.375rem - 1px);
          transform: translateX(-50%); } }
      @media (max-width: 991.98px) {
        .col-lg-1 > .conflict-factions-vs::after {
          transform: translate(calc(1.09524em), -50%); } }
      @media (min-width: 992px) {
        .col-lg-1 > .conflict-factions-vs::after {
          border-left-width: 2px;
          height: calc(100% + 2.625em + 1.375rem - 1px);
          transform: translateX(-50%); } }
    .conflict-factions-vs::before {
      right: 50%;
      transform: translate(calc(-1.09524em), -50%); }
      @media (min-width: 768px) {
        .col-md-2 > .conflict-factions-vs::before {
          display: none; } }
      @media (min-width: 992px) {
        .conflict-factions-vs::before {
          display: none; } }
    .conflict-factions-vs.-continued {
      background: none; }
      .conflict-factions-vs.-continued::after {
        bottom: 0; }
    .vs-wrapper {
      position: relative;
      display: inline-block;
      left: 0.1em; }

.section#allies {
  min-height: 60vh;
  min-height: 40vmax; }

.factions-col ~ .factions-col {
  transition-delay: 0.5s; }

.faction-title {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5em; }
  .faction-title:last-child {
    margin-bottom: 0; }
  .faction-combatant .faction-title {
    line-height: 1.1; }
    .faction-combatant .faction-title:not(:last-child) {
      margin-bottom: 1.25em; }

.faction-ally img {
  font-size: inherit;
  width: 32px;
  height: auto;
  margin-left: calc(-1 * calc(32px + 0.5em));
  margin-right: 0.325em; }

.faction-allies {
  line-height: 2; }
  .faction-allies.-flagged {
    padding-left: calc(32px + 0.5em); }

#allies {
  position: relative; }
  #allies::before, #allies::after {
    width: 50%;
    padding-top: 50%;
    left: 50%;
    top: 9.25em;
    position: absolute;
    background: url("./../media//triangle-fade-tip.svg") center/100% 100% no-repeat;
    transform-origin: bottom left;
    transform: translateY(-100%);
    z-index: 0;
    pointer-events: none; }
  #allies::after {
    transform: translateY(calc(-100% - 1px)) rotate(180deg); }

@media (max-width: 575.98px) {
  #commanders .commander > * {
    margin-left: 10px !important;
    margin-right: 10px !important; } }

#commanders figcaption, #commanders span.caption {
  top: 50%;
  margin: 2em; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    #commanders figcaption, #commanders span.caption {
      top: unset;
      margin: 1em; } }

@media (max-width: 767.98px) {
  #commanders figure, #commanders .figure {
    max-width: 83.33333%;
    margin: 0 auto; } }

@media (max-width: 575.98px) {
  #commanders figure, #commanders .figure {
    max-width: 91.66667%; } }

#weapons {
  overflow: hidden; }
  @media (max-width: 575.98px) {
    #weapons {
      padding-bottom: 4rem !important; } }

.conflict-weapons {
  background-color: rgba(233, 234, 217, 0.1); }
  .conflict-weapons-slider {
    position: relative;
    z-index: 1; }
  .conflict-weapons::before, .conflict-weapons::after {
    display: block;
    position: absolute;
    height: calc(100% + 2px);
    max-width: 25%;
    border: 1px solid currentColor;
    border-right: transparent;
    top: -1px;
    bottom: 0;
    z-index: 0;
    background: linear-gradient(to bottom right, currentColor calc(50% - 0.5px), transparent calc(50% - 0.5px)) top/100% 50% no-repeat, linear-gradient(to top right, currentColor calc(50% - 0.5px), transparent calc(50% - 0.5px)) bottom/100% 50% no-repeat, linear-gradient(to left, rgba(233, 234, 217, 0.1), transparent) no-repeat;
    width: 12.5vw; }
    @media (min-width: 576px) {
      .conflict-weapons::before, .conflict-weapons::after {
        width: calc(50vw - 225px); } }
    @media (min-width: 768px) {
      .conflict-weapons::before, .conflict-weapons::after {
        width: calc(50vw - 300px); } }
    @media (min-width: 992px) {
      .conflict-weapons::before, .conflict-weapons::after {
        width: calc(50vw - 400px); } }
    @media (min-width: 1280px) {
      .conflict-weapons::before, .conflict-weapons::after {
        width: calc(50vw - 536px); } }
  .conflict-weapons::before {
    left: 0px;
    transform: translateX(-100%); }
  .conflict-weapons::after {
    right: 0px;
    transform: translateX(100%) rotate(0.5turn); }

.weapon > figure, .weapon > .figure, .weapon-description {
  backface-visibility: hidden;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95); }

@media (orientation: portrait) and (max-width: 767.98px) {
  .weapon .weapon-content-scroll, .weapon-image {
    padding-top: 1em;
    padding-bottom: 1em; } }

@media (orientation: portrait) and (max-width: 575.98px) {
  .weapon .weapon-content-scroll, .weapon-image {
    padding-top: 2em;
    padding-bottom: 2em; } }

@media (orientation: portrait) {
  @supports (-moz-appearance: none) and (mask-type: alpha) {
    @media (max-width: 767.98px) {
      .weapon-description-content {
        padding-bottom: 1.5em; } }
    @media (max-width: 575.98px) {
      .weapon-description-content {
        padding-bottom: 2.5em; } } } }

.weapon figure, .weapon .figure {
  background: #e9ead9; }
  .weapon figure, .weapon .figure,
  .weapon figure > img,
  .weapon .figure > img {
    width: 100%; }
  .weapon figure::before, .weapon .figure::before {
    height: 50%;
    top: unset;
    bottom: 0; }
    .primary-bg .weapon figure::before, .primary-bg .weapon .figure::before {
      background: linear-gradient(to top, #007bff, rgba(0, 123, 255, 0)), rgba(0, 123, 255, 0); }
    .secondary-bg .weapon figure::before, .secondary-bg .weapon .figure::before {
      background: linear-gradient(to top, #6c757d, rgba(108, 117, 125, 0)), rgba(108, 117, 125, 0); }
    .success-bg .weapon figure::before, .success-bg .weapon .figure::before {
      background: linear-gradient(to top, #122209, rgba(18, 34, 9, 0)), rgba(18, 34, 9, 0); }
    .info-bg .weapon figure::before, .info-bg .weapon .figure::before {
      background: linear-gradient(to top, #17a2b8, rgba(23, 162, 184, 0)), rgba(23, 162, 184, 0); }
    .warning-bg .weapon figure::before, .warning-bg .weapon .figure::before {
      background: linear-gradient(to top, #ffc107, rgba(255, 193, 7, 0)), rgba(255, 193, 7, 0); }
    .danger-bg .weapon figure::before, .danger-bg .weapon .figure::before {
      background: linear-gradient(to top, #dc3545, rgba(220, 53, 69, 0)), rgba(220, 53, 69, 0); }
    .light-bg .weapon figure::before, .light-bg .weapon .figure::before {
      background: linear-gradient(to top, #f8f9fa, rgba(248, 249, 250, 0)), rgba(248, 249, 250, 0); }
    .dark-bg .weapon figure::before, .dark-bg .weapon .figure::before {
      background: linear-gradient(to top, #343a40, rgba(52, 58, 64, 0)), rgba(52, 58, 64, 0); }
    .navy-bg .weapon figure::before, .navy-bg .weapon .figure::before {
      background: linear-gradient(to top, #081420, rgba(8, 20, 32, 0)), rgba(8, 20, 32, 0); }
    .brown-bg .weapon figure::before, .brown-bg .weapon .figure::before {
      background: linear-gradient(to top, #201408, rgba(32, 20, 8, 0)), rgba(32, 20, 8, 0); }
    .charcoal-bg .weapon figure::before, .charcoal-bg .weapon .figure::before {
      background: linear-gradient(to top, #1c1c1d, rgba(28, 28, 29, 0)), rgba(28, 28, 29, 0); }
    .green-bg .weapon figure::before, .green-bg .weapon .figure::before {
      background: linear-gradient(to top, #122209, rgba(18, 34, 9, 0)), rgba(18, 34, 9, 0); }
  .hover .weapon figure:hover::before, .hover .weapon .figure:hover::before, .weapon figure.tab-focus-within::before, .weapon .tab-focus-within.figure::before {
    opacity: 0.7; }
  .weapon figure figcaption, .weapon .figure figcaption, .weapon figure span.caption, .weapon .figure span.caption {
    font-family: "Oswald", "Source Sans Pro", sans-serif;
    letter-spacing: 0.04em; }
    @media (max-width: 575.98px) {
      .weapon figure figcaption, .weapon .figure figcaption, .weapon figure span.caption, .weapon .figure span.caption {
        padding-left: 52px;
        padding-right: 52px; } }
  .weapon figure.tab-focus-within figcaption, .weapon .tab-focus-within.figure figcaption, .weapon figure.tab-focus-within span.caption, .weapon .tab-focus-within.figure span.caption {
    opacity: 1; }
  @media (max-width: 767.98px) {
    .weapon figure::before, .weapon .figure::before {
      opacity: 0.7; }
    .weapon figure figcaption, .weapon .figure figcaption, .weapon figure span.caption, .weapon .figure span.caption {
      opacity: 1; } }

.weapon-image {
  box-sizing: content-box;
  height: calc((100vw - 10px) * 11 / 12 * 0.51306); }
  @media (min-width: 576px) {
    .weapon-image {
      height: 230.87687px; } }
  @media (min-width: 768px) {
    .weapon-image {
      height: 307.83582px; } }
  @media (min-width: 992px) {
    .weapon-image {
      height: 410.44776px; } }
  @media (min-width: 1280px) {
    .weapon-image {
      height: 550px; } }

.weapon-content-scroll {
  overflow-y: auto;
  padding: 5%;
  border: 5px solid #e9ead9;
  display: flex;
  justify-content: flex-start;
  flex-direction: column; }
  .weapon-content-scroll > div {
    flex: 1 0 auto; }

.weapon-description {
  transform: rotateX(180deg);
  z-index: -1; }
  .weapon-content-scroll, .weapon-description, .weapon-description::before,
  .weapon-description > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .weapon-description::before {
    z-index: 0;
    background-color: inherit;
    opacity: 0.9;
    pointer-events: none; }
  .weapon-description img {
    pointer-events: none;
    z-index: 1;
    opacity: 0.4; }
  .weapon-description-content {
    position: relative;
    z-index: 2; }
    .weapon-description-content p {
      max-width: 25em; }
    .weapon-description-content .h4::after {
      width: 140px;
      border-top: 1px solid currentColor;
      display: block;
      margin: 0.75em auto -0.75em; }
    @media (max-width: 575.98px) {
      .weapon-description-content p {
        font-size: 1rem; } }

.weapon-button {
  color: #000;
  position: absolute;
  right: 5px;
  bottom: 5px;
  margin: 5px;
  z-index: 4; }
  .weapon-button svg {
    pointer-events: none; }
  .weapon-button .inline-svg {
    position: relative; }
  .weapon-button #arrows,
  .weapon-button #bg {
    transition: color 0.3s ease; }
  .weapon-button #arrows {
    color: white; }
  .weapon-button #bg {
    color: transparent;
    fill: currentColor; }
  .weapon-button.tab-focus:focus #arrows, .weapon-button:hover #arrows {
    color: #e9ead9; }
  .primary-bg .weapon-button.tab-focus:focus #bg, .primary-bg .weapon-button:hover #bg {
    color: #489df4; }
  .secondary-bg .weapon-button.tab-focus:focus #bg, .secondary-bg .weapon-button:hover #bg {
    color: #93999a; }
  .success-bg .weapon-button.tab-focus:focus #bg, .success-bg .weapon-button:hover #bg {
    color: #556049; }
  .info-bg .weapon-button.tab-focus:focus #bg, .info-bg .weapon-button:hover #bg {
    color: #58b9c3; }
  .warning-bg .weapon-button.tab-focus:focus #bg, .warning-bg .weapon-button:hover #bg {
    color: #f8ce48; }
  .danger-bg .weapon-button.tab-focus:focus #bg, .danger-bg .weapon-button:hover #bg {
    color: #e06c72; }
  .light-bg .weapon-button.tab-focus:focus #bg, .light-bg .weapon-button:hover #bg {
    color: #f4f5f0; }
  .dark-bg .weapon-button.tab-focus:focus #bg, .dark-bg .weapon-button:hover #bg {
    color: #6b706f; }
  .navy-bg .weapon-button.tab-focus:focus #bg, .navy-bg .weapon-button:hover #bg {
    color: #4e565a; }
  .brown-bg .weapon-button.tab-focus:focus #bg, .brown-bg .weapon-button:hover #bg {
    color: #5e5649; }
  .charcoal-bg .weapon-button.tab-focus:focus #bg, .charcoal-bg .weapon-button:hover #bg {
    color: #5c5c57; }
  .green-bg .weapon-button.tab-focus:focus #bg, .green-bg .weapon-button:hover #bg {
    color: #556049; }

.weapon.active > figure, .weapon.active > .figure {
  transform: rotateX(180deg); }

.weapon.active > .weapon-description {
  transform: rotateX(0);
  z-index: initial; }

.slider-nav {
  color: white;
  transform: translate(calc(100% + 2rem), -50%); }
  @media (max-width: 767.98px) {
    .slider-nav {
      transform: translate(calc(100% + 1rem), -50%); } }
  html:not(.no-js) .slider-nav {
    display: flex; }

.slider-meta {
  flex-direction: inherit;
  align-items: inherit; }

@media (max-width: 991.98px) {
  .slider-prev img, .slider-next img {
    transform-origin: top;
    transform: scale(0.75); } }

@media (max-width: 575.98px) {
  .slider-inactive {
    opacity: 0.2;
    visibility: visible;
    cursor: default !important; }
  .slider-prev, .slider-next {
    order: 2;
    min-width: 40px; }
    .slider-prev img, .slider-next img {
      transform-origin: center; }
  .slider-nav {
    flex-direction: row;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0.5rem);
    align-items: center;
    justify-content: center;
    width: 100%; }
  .slider-sep {
    transform: rotate(90deg); }
  .slider-meta {
    margin: 0 auto 0 1.33333em; } }

.conflict-next {
  transition: padding-top 1s ease, padding-bottom 1s ease; }
  .conflict-next::before, .conflict-next::after {
    opacity: 0.7;
    width: 150%;
    padding-top: 60%;
    background: radial-gradient(ellipse farthest-side at 33% 50%, transparent, rgba(0, 0, 0, 0.9));
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 0;
    transition: background-color 1s ease; }
    @media (max-width: 575.98px) {
      .conflict-next::before, .conflict-next::after {
        background: radial-gradient(ellipse farthest-side at 55% 50%, transparent, rgba(0, 0, 0, 0.9)); } }
  .conflict-next-bg, .ssb-preview-bg {
    z-index: -1;
    pointer-events: none;
    transition: transform 1s ease;
    min-height: 100%;
    min-width: 100%;
    max-width: unset;
    width: auto !important;
    height: auto !important; }
  .conflict-next-wrapper {
    overflow: hidden;
    display: block; }
    @media (min-width: 1280px) {
      .conflict-next-wrapper:hover .conflict-next, .conflict-next-wrapper.tab-focus:focus .conflict-next {
        padding-top: 20px;
        padding-bottom: 20px; } }
    .conflict-next-wrapper:hover .conflict-next-bg, .conflict-next-wrapper:hover .ssb-preview-bg, .conflict-next-wrapper.tab-focus:focus .conflict-next-bg, .conflict-next-wrapper.tab-focus:focus .ssb-preview-bg {
      transform: translate(-50%, -50%) scale(1.125); }
    .conflict-next-wrapper:hover .conflict-next::before, .conflict-next-wrapper.tab-focus:focus .conflict-next::before {
      background-color: rgba(0, 0, 0, 0.4); }
  .conflict-next,
  .conflict-next .container {
    z-index: 1; }
  .conflict-next .container p,
  .conflict-next .container img {
    z-index: 2; }
  .conflict-next .container strong {
    letter-spacing: 0.02em; }
  .next-arrow {
    align-self: center; }
    @media (max-width: 575.98px) {
      .next-arrow {
        margin-top: 0 !important;
        margin-left: auto; } }

.conflict-back .next-arrow {
  margin-left: 0;
  margin-right: auto;
  right: unset;
  left: 0;
  transform: rotate(90deg); }
  @media (min-width: 576px) {
    .conflict-back .next-arrow {
      transform: translateY(-50%) rotate(90deg);
      left: 5px; } }

#pivotal-battle {
  min-height: 100vh;
  z-index: 2; }

.pivotal-battle-bg {
  overflow: hidden;
  z-index: 1; }
  @supports (position: sticky) {
    .pivotal-battle-bg + section {
      margin-top: -100vh;
      padding-top: 100vh;
      margin-bottom: 150vh; } }
  .pivotal-battle-bg img {
    min-width: 100%;
    min-height: 100vh;
    height: auto;
    max-width: unset;
    position: relative;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw; }

.pivotal-battle-wrapper {
  display: flex;
  flex-flow: column nowrap; }

.pivotal-battle-content h3 {
  margin-bottom: 1em; }

#aftermath {
  margin-top: -10%;
  z-index: 1;
  position: relative; }

.footer {
  font-size: 1rem;
  line-height: 1.33333;
  font-family: "Source Sans Pro", sans-serif;
  padding: 2rem 0;
  display: flex;
  justify-content: space-around; }
  .footer a {
    transition: opacity 200ms ease; }
    .footer a, .footer a:visited {
      color: inherit; }
    .footer a.tab-focus:focus, .footer a:hover {
      opacity: 0.5; }
  .footer-credits {
    margin-top: 0.25rem; }
  .footer-production-credit {
    margin-bottom: 1.25rem;
    max-width: 30em; }
  .footer-production-logos {
    margin: -0.625rem;
    margin-top: 0.375rem;
    flex-wrap: wrap; }
    @media (min-width: 576px) {
      .footer-production-logos {
        flex-wrap: nowrap; } }
    .footer-production-logos > div {
      padding: 0.625rem; }
    .footer-production-logos > hr {
      pointer-events: none;
      border: none;
      opacity: 0;
      visibility: hidden;
      flex: 1 0 100%;
      margin: 0;
      padding: 0; }
  .footer-social {
    overflow: hidden; }
    .footer-social .social-icons {
      min-height: 2.875em; }
  .footer-meta {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 1.5rem -0.72727em 0;
    opacity: 0.75; }
    .footer-meta > small {
      margin: 0 0.72727em; }
    .footer-meta a.tab-focus:focus, .footer-meta a:hover {
      text-decoration: underline; }
    html.touch .footer-meta a {
      text-decoration: underline; }
  @media (max-width: 991.98px) {
    .footer .row {
      justify-content: space-around;
      align-items: center; }
    .footer-credits, .footer-social, .footer-meta {
      margin-top: 30px; } }
  @media (max-width: 575.98px) {
    .footer {
      padding: 12.5% 10%; }
      .footer .row > div {
        margin-left: auto;
        margin-right: auto; }
      .footer-meta {
        text-align: center; }
        .footer-meta,
        .footer-meta > small {
          display: block; }
        .footer-meta > small {
          margin: 0.25em; }
      .footer-production-logos {
        justify-content: space-evenly; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .footer-credits {
      order: 3; }
    .footer-social {
      order: 2;
      align-self: center;
      margin-top: 0; } }

.header {
  font-size: 4.375rem;
  z-index: 20; }
  @media (max-width: 1200px) {
    .header {
      font-size: calc(1.3375rem + 4.05vw) ; } }
  .header:not(.pin) {
    position: relative; }
  .header::before {
    content: "";
    z-index: 0 !important;
    display: block;
    width: 200%;
    height: 200%;
    pointer-events: none;
    background: radial-gradient(ellipse closest-side at center, transparent, black); }
  .header-background {
    z-index: -1;
    display: block;
    min-height: calc(100% + 2px);
    min-width: calc(100% + 2px);
    max-width: unset;
    max-height: unset;
    width: auto;
    height: auto;
    pointer-events: none; }
    @media (max-width: 991.98px) {
      img.header-background + video.header-background {
        display: none; } }
    @media screen and (prefers-reduced-motion: reduce) {
      img.header-background + video.header-background {
        display: none; } }
  .header video::-webkit-media-controls {
    display: none; }
  .header-particles {
    pointer-events: none;
    width: 110%;
    height: 110%; }
    .header-particles > canvas {
      transition: opacity 500ms ease-out; }
      .header-particles > canvas.particles-hide {
        opacity: 0; }
  .header-content {
    z-index: 1;
    position: relative;
    text-align: center; }
    .header-content a, .header-content a:hover {
      color: inherit;
      text-decoration: none; }
    .header-content a:link:hover {
      opacity: 0.75; }
    .header-content-scroll {
      padding: 2.25em 2rem; }
      @media (min-width: 576px) {
        .header-content-scroll {
          padding: 1.5em 0; } }
      @media (min-width: 992px) {
        .header-content-scroll {
          padding: 1.25em 4rem;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow-x: hidden;
          overflow-y: auto;
          scrollbar-width: thin;
          scrollbar-color: #1c1c1d rgba(255, 255, 255, 0.1); }
          .header-content-scroll::-webkit-scrollbar {
            background-color: #1c1c1d;
            width: 0.5rem;
            height: 0.5rem; }
          .header-content-scroll::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 99999px; } }

.home {
  background: inherit; }
  .home-header-background {
    overflow: hidden;
    z-index: -1;
    height: 100vh;
    width: 100%; }
  @supports (position: sticky) {
    .home-header-background + .home-header-panel {
      margin-top: -100vh; } }
  .home-header-wrapper {
    z-index: 1; }
  .home-header-panel {
    font-size: inherit;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    text-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
    min-height: 100vh;
    padding-top: 3.5em;
    padding-bottom: 3.5em; }
    @media (min-width: 576px) {
      .home-header-panel {
        padding-top: 5em;
        padding-bottom: 5em; } }
    @media (min-width: 768px) {
      .home-header-panel {
        padding-top: 7em;
        padding-bottom: 7em; } }
  .home-intro strong {
    font-weight: 700; }
    .home-intro strong:first-of-type {
      font-size: 1.181818em; }
  .home-broadcast::before {
    border-top: 3px solid currentColor;
    display: block;
    width: 3.5rem;
    margin: 1.2em auto;
    box-shadow: 0 0 7px rgba(255, 255, 255, 0.5); }
  .home-trailer {
    font-size: 12.5rem;
    letter-spacing: -0.01em;
    font-family: "Oswald", "Source Sans Pro", sans-serif;
    color: rgba(255, 255, 255, 0.1);
    line-height: 1;
    margin-bottom: -0.275em; }
    @media (max-width: 1200px) {
      .home-trailer {
        font-size: calc(2.15rem + 13.8vw) ; } }
  @media (min-width: 768px) {
    .home .creatives-intro {
      margin-left: 1.166667em;
      margin-right: 1.166667em; } }
  @media (max-width: 767.98px) {
    .home .m-lead-lg {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 768px) {
    .home #creatives .section-copy,
    .home #meet-the-host .section-copy {
      padding-left: 0; } }

#home-logo {
  animation: homeLogo 1.75s 0.3s ease-out both; }

@keyframes homeLogo {
  0% {
    filter: blur(40px);
    transform: scale(0.75);
    opacity: 0; }
  80% {
    opacity: 1;
    filter: blur(0); }
  100% {
    transform: scale(1); } }

@keyframes homeLogo_alt {
  0% {
    transform: scale(0.75);
    opacity: 0; }
  80% {
    opacity: 1; }
  100% {
    transform: scale(1); } }
  @media screen and (min-width: 0\0) and (min-resolution: 72dpi) {
    #home-logo {
      -ms-filter: "";
      filter: "";
      animation: homeLogo_alt 1.75s 0.3s ease-out both; } }
  @supports (-ms-accelerator: true) and (not (color: unset)) {
    #home-logo {
      filter: none;
      animation: homeLogo_alt 1.75s 0.3s ease-out both; } }

#title a {
  position: absolute;
  bottom: 3em; }
  #title a svg,
  #title a img {
    animation: homeArrow 0.5s 3s ease-out both; }

@keyframes homeArrow {
  from {
    transform: translateY(-75%);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

#meet-the-host .row {
  align-items: center; }

#meet-the-host img {
  opacity: 0.63; }

@media (max-width: 1023.98px) {
  body.home .nav:not(.nav-hide-js) {
    pointer-events: auto !important;
    background: inherit !important; }
    body.home .nav:not(.nav-hide-js)::before {
      opacity: 1 !important; } }

@media (max-width: 767.98px) {
  #desktop-bg-video {
    display: none; } }

@media (min-width: 768px) {
  #mobile-bg-video {
    display: none; } }

.conflict_template.iroquois-wars .conflict-gallery {
  flex-flow: column wrap;
  justify-content: flex-start; }
  @media (max-width: 991.98px) {
    .conflict_template.iroquois-wars .conflict-gallery {
      max-height: 500px; }
      .conflict_template.iroquois-wars .conflict-gallery a {
        width: 45.12671%; }
        .conflict_template.iroquois-wars .conflict-gallery a:first-of-type {
          width: 38.20663%; } }
  @media (max-width: 767.98px) {
    .conflict_template.iroquois-wars .conflict-gallery {
      max-height: 400px; }
      .conflict_template.iroquois-wars .conflict-gallery a {
        width: 54.15205%; }
        .conflict_template.iroquois-wars .conflict-gallery a:first-of-type {
          width: 45.84795%; } }
  @media (max-width: 575.98px) {
    .conflict_template.iroquois-wars .conflict-gallery {
      max-height: 80vw; } }
  @media (min-width: 992px) {
    .conflict_template.iroquois-wars .conflict-gallery {
      max-height: 700px; } }

.conflict_template.iroquois-wars .location-description {
  max-width: 29em; }

.conflict_template.iroquois-wars .location-blob {
  top: 61.5%;
  left: 73.5%;
  width: 45.45217%; }

.conflict_template.iroquois-wars .pivotal-battle-bg img {
  height: calc(100vw * 1818 / 1440) !important;
  min-width: calc(100vh * 1440 / 1818) !important; }

.conflict_template.king-philips-war .conflict-gallery {
  align-items: flex-start; }
  @media (max-width: 1279.98px) {
    .conflict_template.king-philips-war .conflict-gallery a {
      flex: 0 1 calc((100% - 1px) / 3); } }

.conflict_template.king-philips-war .location-description {
  max-width: 29em; }

.conflict_template.king-philips-war .location-blob {
  left: 78%;
  top: 63%;
  width: 43.92522%; }

.conflict_template.king-philips-war .pivotal-battle-bg img {
  height: calc(100vw * 1082 / 1438) !important;
  min-width: calc(100vh * 1438 / 1082) !important; }

@media (max-width: 1279.98px) {
  .conflict_template.seven-years-war .conflict-gallery a {
    flex: 0 1 45%;
    align-self: flex-end; }
    .conflict_template.seven-years-war .conflict-gallery a:nth-of-type(n + 3) {
      align-self: flex-start; }
    .conflict_template.seven-years-war .conflict-gallery a:nth-last-of-type(3), .conflict_template.seven-years-war .conflict-gallery a:nth-last-of-type(2) {
      flex-basis: 40%; }
    .conflict_template.seven-years-war .conflict-gallery a:last-of-type {
      flex-basis: 20%; } }

.conflict_template.seven-years-war .location-blob {
  top: 53.4%;
  left: 46.7%;
  width: 58.81643%; }

.conflict_template.seven-years-war .pivotal-battle-bg img {
  height: calc(100vw * 1000 / 1440) !important;
  min-width: calc(100vh * 1440 / 1000) !important; }

.conflict_template.pontiacs-war .conflict-gallery a {
  align-self: flex-start; }
  .conflict_template.pontiacs-war .conflict-gallery a:nth-last-of-type(n + 3) {
    align-self: flex-end; }

@media (min-width: 1280px) {
  .conflict_template.pontiacs-war .conflict-gallery > hr:nth-of-type(3) {
    display: block; }
  .conflict_template.pontiacs-war .conflict-gallery a:nth-of-type(3) {
    margin-right: 190px; }
  .conflict_template.pontiacs-war .conflict-gallery a:nth-of-type(4) {
    margin-left: 190px; } }

@media (max-width: 1279.98px) {
  .conflict_template.pontiacs-war .conflict-gallery a {
    flex-basis: 25%; }
    .conflict_template.pontiacs-war .conflict-gallery a:first-of-type, .conflict_template.pontiacs-war .conflict-gallery a:last-of-type {
      flex-basis: 40%; } }

@media (max-width: 991.98px) {
  .conflict_template.pontiacs-war .conflict-gallery a {
    flex-basis: 30%; } }

.conflict_template.pontiacs-war .location-blob {
  top: 39.5%;
  left: 72.1%;
  width: 30.51948%; }

.conflict_template.pontiacs-war .pivotal-battle-bg img {
  height: calc(100vw * 803 / 1440) !important;
  min-width: calc(100vh * 1440 / 803) !important; }

@media (max-width: 991.98px) {
  .conflict_template.american-revolutionary-war .conflict-gallery a {
    flex-basis: 60%; }
    .conflict_template.american-revolutionary-war .conflict-gallery a:first-of-type {
      flex-basis: 31.95%; }
    .conflict_template.american-revolutionary-war .conflict-gallery a:nth-of-type(3) {
      flex-basis: 60.95%; }
    .conflict_template.american-revolutionary-war .conflict-gallery a:last-of-type {
      flex-basis: 39.05%; } }

.conflict_template.american-revolutionary-war .location-blob {
  top: 61%;
  left: 71%;
  width: 51.68394%; }

.conflict_template.american-revolutionary-war .pivotal-battle-bg img {
  height: calc(100vw * 813 / 1440) !important;
  min-width: calc(100vh * 1440 / 813) !important; }

@media (min-width: 1280px) {
  .conflict_template.red-clouds-war .conflict-gallery a:nth-of-type(4) {
    margin-left: 154px; } }

@media (max-width: 1279.98px) {
  .conflict_template.red-clouds-war .conflict-gallery a {
    align-self: flex-end; }
    .conflict_template.red-clouds-war .conflict-gallery a:nth-of-type(n + 4) {
      align-self: flex-start; }
    .conflict_template.red-clouds-war .conflict-gallery a:first-of-type {
      flex-basis: 24.44444%; }
    .conflict_template.red-clouds-war .conflict-gallery a:nth-of-type(2) {
      flex-basis: 36.06838%; }
    .conflict_template.red-clouds-war .conflict-gallery a:nth-of-type(3) {
      flex-basis: 39.48718%; }
    .conflict_template.red-clouds-war .conflict-gallery a:nth-of-type(4) {
      flex-basis: 24.01709%;
      margin-left: 13.16239%; }
    .conflict_template.red-clouds-war .conflict-gallery a:last-of-type {
      flex-basis: 40.25641%; } }

.conflict_template.red-clouds-war .location-blob {
  left: 35%;
  top: 39%;
  width: 36%; }

.conflict_template.red-clouds-war .pivotal-battle-bg img {
  height: calc(100vw * 779 / 1438) !important;
  min-width: calc(100vh * 1438 / 779) !important; }

.conflict_template.nez-perce-war .conflict-gallery > a {
  flex: 0 1 33%;
  align-self: flex-end; }

@media (min-width: 1280px) {
  .conflict_template.nez-perce-war .conflict-gallery {
    display: block;
    margin-left: -40px;
    margin-right: -40px; }
    .conflict_template.nez-perce-war .conflict-gallery > a {
      float: left; } }

.conflict_template.nez-perce-war .location-blob {
  left: 18.25%;
  top: 23.5%;
  width: 44.02597%; }

.conflict_template.nez-perce-war .pivotal-battle-bg img {
  height: calc(100vw * 772 / 1440) !important;
  min-width: calc(100vh * 1440 / 772) !important; }

.conflict_template.northwest-resistance .conflict-gallery {
  align-items: flex-start; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .conflict_template.northwest-resistance .conflict-gallery > a:nth-of-type(7) {
      flex-basis: 33%; } }
  @media (max-width: 991.98px) {
    .conflict_template.northwest-resistance .conflict-gallery > a {
      flex: 0 1 25%;
      align-self: flex-end; }
      .conflict_template.northwest-resistance .conflict-gallery > a:nth-of-type(4) ~ a {
        align-self: flex-start; }
      .conflict_template.northwest-resistance .conflict-gallery > a:nth-of-type(5) {
        margin-left: auto; } }
  @media (min-width: 992px) and (max-width: 1279.98px) {
    .conflict_template.northwest-resistance .conflict-gallery > a:nth-of-type(6) {
      order: 7;
      position: relative;
      right: 53px; } }
  @media (min-width: 1280px) {
    .conflict_template.northwest-resistance .conflict-gallery {
      margin-left: -4px;
      margin-right: -4px; }
      .conflict_template.northwest-resistance .conflict-gallery > a:nth-of-type(4) {
        margin-right: auto; }
      .conflict_template.northwest-resistance .conflict-gallery > a:nth-of-type(5) {
        margin-left: auto; } }

.conflict_template.northwest-resistance .location-blob {
  left: 36.5%;
  top: 53.25%;
  width: 42.35416%; }

.conflict_template.northwest-resistance .pivotal-battle-bg img {
  height: calc(100vw * 827 / 1438) !important;
  min-width: calc(100vh * 1438 / 827) !important; }

.img-kp-header-bg-jpg {
  height: calc(1024 / 1836 * 100vw) !important; }

.img-sy-header-bg-jpg {
  height: calc(1024 / 1833 * 100vw) !important; }

.img-po-header-bg-jpg {
  height: calc(1024 / 1835 * 100vw) !important; }

.img-ar-header-bg-jpg {
  height: calc(1024 / 1837 * 100vw) !important; }

.img-rc-header-bg-jpg {
  height: calc(1024 / 1852 * 100vw) !important; }

.img-np-header-bg-jpg {
  height: calc(1024 / 1830 * 100vw) !important; }

.img-nr-header-bg-jpg {
  height: calc(1024 / 1823 * 100vw) !important; }

body {
  color: white;
  background: #081420; }

.site {
  background: inherit; }

.main-inner {
  animation: simpleFadeIn 0.5s 0.8s ease-out both; }

.ssb-naw-presents {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: white;
  max-width: 10em;
  margin: 0 auto 1.5em; }
  .ssb-preview .ssb-naw-presents {
    letter-spacing: 0.145em;
    max-width: unset; }
  @media (min-width: 992px) {
    #conflicts-intro .ssb-naw-presents {
      animation: simpleFadeIn 0.3s 1.5s ease-out both; } }

.ssb-title {
  padding-bottom: 0.454545em;
  margin-top: 0.454545em;
  margin-bottom: -2.35%; }
  .ssb-preview .ssb-title {
    margin-top: 0; }
  @media (min-width: 992px) {
    #conflicts-intro .ssb-title {
      animation: simpleFadeIn 0.5s 2s ease-out both; } }
  @media (min-height: 900px) {
    #conflicts-intro .ssb-title {
      margin-top: 1.454545em; } }

.ssb-subtitle {
  font-size: 2.875rem;
  line-height: 1.25;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #e9ead9;
  margin-bottom: 0; }
  @media (max-width: 1200px) {
    .ssb-subtitle {
      font-size: calc(1.1875rem + 2.25vw) ; } }
  .ssb-preview .ssb-subtitle {
    font-weight: 700;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }
  @media (min-width: 992px) {
    #conflicts-intro .ssb-subtitle {
      animation: simpleFadeIn 0.5s 2.75s ease-out both; } }

.ssb-intro {
  font-family: "Source Sans Pro", sans-serif;
  margin-top: 1em;
  opacity: 80%; }
  @media (min-width: 992px) {
    #conflicts-intro .ssb-intro {
      animation: simpleFadeIn 0.5s 3.25s ease-out both; } }

.ssb-scroll {
  display: block;
  width: fit-content; }
  @supports not (width: fit-content) {
    .ssb-scroll {
      max-width: 20em; } }
  .ssb-scroll img {
    display: block;
    margin: 1.25rem auto 0; }
  @media (min-width: 768px) {
    .ssb-scroll {
      transform: translateY(50%); } }
  @media (min-width: 992px) {
    #conflicts-intro .ssb-scroll {
      animation: simpleFadeIn 0.6s 3.75s ease-out both; } }

.ssb-arrow, .next-arrow {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  transition: opacity 0.3s ease;
  margin-top: 2em;
  transform: rotate(-90deg); }
  .ssb-arrow:hover, .next-arrow:hover {
    opacity: 0.5; }
  @media (min-width: 576px) {
    .ssb-arrow, .next-arrow {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      margin-top: 0; } }

.ssb-preview {
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .ssb-preview-bg {
    transform-origin: center; }
  @media (max-width: 1279.98px) {
    .ssb-preview:hover .ssb-preview-bg {
      transform: translate(-50%, -50%) scale(1.1); } }
  .ssb-preview .container > .row {
    padding-top: 0;
    padding-bottom: 0;
    transition: padding-top 1s ease, padding-bottom 1s ease; }
  @media (min-width: 1280px) {
    .ssb-preview a:hover .container > .row,
    .ssb-preview a.tab-focus:focus .container > .row {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .ssb-preview::before {
    background-color: rgba(0, 0, 0, 0.08);
    z-index: 0; }
  .ssb-preview a, .ssb-preview a:visited {
    color: white; }
  .ssb-preview a.tab-focus:focus {
    outline: auto; }

@media (min-width: 992px) {
  #conflicts-intro .conflict-header {
    display: block; } }

.blue-text {
  color: #007bff !important; }

.blue-bg,
.blue-bg .inherited-bg {
  background-color: #007bff !important; }

.indigo-text {
  color: #6610f2 !important; }

.indigo-bg,
.indigo-bg .inherited-bg {
  background-color: #6610f2 !important; }

.purple-text {
  color: #6f42c1 !important; }

.purple-bg,
.purple-bg .inherited-bg {
  background-color: #6f42c1 !important; }

.pink-text {
  color: #e83e8c !important; }

.pink-bg,
.pink-bg .inherited-bg {
  background-color: #e83e8c !important; }

.red-text {
  color: #dc3545 !important; }

.red-bg,
.red-bg .inherited-bg {
  background-color: #dc3545 !important; }

.orange-text {
  color: #fd7e14 !important; }

.orange-bg,
.orange-bg .inherited-bg {
  background-color: #fd7e14 !important; }

.yellow-text {
  color: #ffc107 !important; }

.yellow-bg,
.yellow-bg .inherited-bg {
  background-color: #ffc107 !important; }

.green-text {
  color: #122209 !important; }

.green-bg,
.green-bg .inherited-bg {
  background-color: #122209 !important; }

.teal-text {
  color: #20c997 !important; }

.teal-bg,
.teal-bg .inherited-bg {
  background-color: #20c997 !important; }

.cyan-text {
  color: #17a2b8 !important; }

.cyan-bg,
.cyan-bg .inherited-bg {
  background-color: #17a2b8 !important; }

.white-text {
  color: white !important; }

.white-bg,
.white-bg .inherited-bg {
  background-color: white !important; }

.gray-text {
  color: #6c757d !important; }

.gray-bg,
.gray-bg .inherited-bg {
  background-color: #6c757d !important; }

.gray-dark-text {
  color: #343a40 !important; }

.gray-dark-bg,
.gray-dark-bg .inherited-bg {
  background-color: #343a40 !important; }

.white-text {
  color: white !important; }

.white-bg,
.white-bg .inherited-bg {
  background-color: white !important; }

.off-white-text {
  color: #e9ead9 !important; }

.off-white-bg,
.off-white-bg .inherited-bg {
  background-color: #e9ead9 !important; }

.linen-text {
  color: #e9ead9 !important; }

.linen-bg,
.linen-bg .inherited-bg {
  background-color: #e9ead9 !important; }

.navy-text {
  color: #081420 !important; }

.navy-bg,
.navy-bg .inherited-bg {
  background-color: #081420 !important; }

.brown-text {
  color: #201408 !important; }

.brown-bg,
.brown-bg .inherited-bg {
  background-color: #201408 !important; }

.charcoal-text {
  color: #1c1c1d !important; }

.charcoal-bg,
.charcoal-bg .inherited-bg {
  background-color: #1c1c1d !important; }

.green-text {
  color: #122209 !important; }

.green-bg,
.green-bg .inherited-bg {
  background-color: #122209 !important; }

.black-text {
  color: black !important; }

.black-bg,
.black-bg .inherited-bg {
  background-color: black !important; }

.inherit-bg {
  background: inherit !important; }

.dim-light {
  opacity: 0.75; }

.dim, .dim-medium {
  opacity: 0.5; }

.dim-extra {
  opacity: 0.2; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.block {
  display: block !important; }

.inline {
  display: inline !important; }

.inline-block {
  display: inline-block !important; }

.flex {
  display: flex !important; }

.hide-xs {
  display: none !important; }
  @media (max-width: 575.98px) {
    .hide-xs-down {
      display: none !important; } }
  @media (max-width: 575.98px) {
    .hide-xs-only {
      display: none !important; } }

@media (min-width: 576px) {
  .hide-sm {
    display: none !important; } }

@media (max-width: 767.98px) {
  .hide-sm-down {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .hide-sm-only {
    display: none !important; } }

@media (min-width: 768px) {
  .hide-md {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hide-md-down {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hide-md-only {
    display: none !important; } }

@media (min-width: 992px) {
  .hide-lg {
    display: none !important; } }

@media (max-width: 1279.98px) {
  .hide-lg-down {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1279.98px) {
  .hide-lg-only {
    display: none !important; } }

@media (min-width: 1280px) {
  .hide-xl {
    display: none !important; } }

.hide-xl-down {
  display: none !important; }

@media (min-width: 1280px) {
  .hide-xl-only {
    display: none !important; } }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.fixed {
  position: fixed; }

.static {
  position: static; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-auto {
  overflow: auto !important; }

.oval,
.circle {
  border-radius: 50% !important; }

.pill {
  border-radius: 99999px !important; }

.flex-none {
  flex: none; }

/*********************
SPACING STYLES
*********************/
.mt-10 {
  margin-top: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.mt-130 {
  margin-top: 130px !important; }

.mb-130 {
  margin-bottom: 130px !important; }

.pt-130 {
  padding-top: 130px !important; }

.pb-130 {
  padding-bottom: 130px !important; }

@media (max-width: 991.98px) {
  .mt-10 {
    margin-top: 9px !important; }
  .mb-10 {
    margin-bottom: 9px !important; }
  .pt-10 {
    padding-top: 9px !important; }
  .pb-10 {
    padding-bottom: 9px !important; }
  .mt-20 {
    margin-top: 18px !important; }
  .mb-20 {
    margin-bottom: 18px !important; }
  .pt-20 {
    padding-top: 18px !important; }
  .pb-20 {
    padding-bottom: 18px !important; }
  .mt-30 {
    margin-top: 27px !important; }
  .mb-30 {
    margin-bottom: 27px !important; }
  .pt-30 {
    padding-top: 27px !important; }
  .pb-30 {
    padding-bottom: 27px !important; }
  .mt-40 {
    margin-top: 35px !important; }
  .mb-40 {
    margin-bottom: 35px !important; }
  .pt-40 {
    padding-top: 35px !important; }
  .pb-40 {
    padding-bottom: 35px !important; }
  .mt-50 {
    margin-top: 44px !important; }
  .mb-50 {
    margin-bottom: 44px !important; }
  .pt-50 {
    padding-top: 44px !important; }
  .pb-50 {
    padding-bottom: 44px !important; }
  .mt-60 {
    margin-top: 53px !important; }
  .mb-60 {
    margin-bottom: 53px !important; }
  .pt-60 {
    padding-top: 53px !important; }
  .pb-60 {
    padding-bottom: 53px !important; }
  .mt-70 {
    margin-top: 62px !important; }
  .mb-70 {
    margin-bottom: 62px !important; }
  .pt-70 {
    padding-top: 62px !important; }
  .pb-70 {
    padding-bottom: 62px !important; }
  .mt-80 {
    margin-top: 70px !important; }
  .mb-80 {
    margin-bottom: 70px !important; }
  .pt-80 {
    padding-top: 70px !important; }
  .pb-80 {
    padding-bottom: 70px !important; }
  .mt-90 {
    margin-top: 79px !important; }
  .mb-90 {
    margin-bottom: 79px !important; }
  .pt-90 {
    padding-top: 79px !important; }
  .pb-90 {
    padding-bottom: 79px !important; }
  .mt-100 {
    margin-top: 88px !important; }
  .mb-100 {
    margin-bottom: 88px !important; }
  .pt-100 {
    padding-top: 88px !important; }
  .pb-100 {
    padding-bottom: 88px !important; }
  .mt-130 {
    margin-top: 114px !important; }
  .mb-130 {
    margin-bottom: 114px !important; }
  .pt-130 {
    padding-top: 114px !important; }
  .pb-130 {
    padding-bottom: 114px !important; } }

@media (max-width: 575.98px) {
  .mt-10 {
    margin-top: 8px !important; }
  .mb-10 {
    margin-bottom: 8px !important; }
  .pt-10 {
    padding-top: 8px !important; }
  .pb-10 {
    padding-bottom: 8px !important; }
  .mt-20 {
    margin-top: 15px !important; }
  .mb-20 {
    margin-bottom: 15px !important; }
  .pt-20 {
    padding-top: 15px !important; }
  .pb-20 {
    padding-bottom: 15px !important; }
  .mt-30 {
    margin-top: 23px !important; }
  .mb-30 {
    margin-bottom: 23px !important; }
  .pt-30 {
    padding-top: 23px !important; }
  .pb-30 {
    padding-bottom: 23px !important; }
  .mt-40 {
    margin-top: 30px !important; }
  .mb-40 {
    margin-bottom: 30px !important; }
  .pt-40 {
    padding-top: 30px !important; }
  .pb-40 {
    padding-bottom: 30px !important; }
  .mt-50 {
    margin-top: 38px !important; }
  .mb-50 {
    margin-bottom: 38px !important; }
  .pt-50 {
    padding-top: 38px !important; }
  .pb-50 {
    padding-bottom: 38px !important; }
  .mt-60 {
    margin-top: 45px !important; }
  .mb-60 {
    margin-bottom: 45px !important; }
  .pt-60 {
    padding-top: 45px !important; }
  .pb-60 {
    padding-bottom: 45px !important; }
  .mt-70 {
    margin-top: 53px !important; }
  .mb-70 {
    margin-bottom: 53px !important; }
  .pt-70 {
    padding-top: 53px !important; }
  .pb-70 {
    padding-bottom: 53px !important; }
  .mt-80 {
    margin-top: 60px !important; }
  .mb-80 {
    margin-bottom: 60px !important; }
  .pt-80 {
    padding-top: 60px !important; }
  .pb-80 {
    padding-bottom: 60px !important; }
  .mt-90 {
    margin-top: 68px !important; }
  .mb-90 {
    margin-bottom: 68px !important; }
  .pt-90 {
    padding-top: 68px !important; }
  .pb-90 {
    padding-bottom: 68px !important; }
  .mt-100 {
    margin-top: 75px !important; }
  .mb-100 {
    margin-bottom: 75px !important; }
  .pt-100 {
    padding-top: 75px !important; }
  .pb-100 {
    padding-bottom: 75px !important; }
  .mt-130 {
    margin-top: 98px !important; }
  .mb-130 {
    margin-bottom: 98px !important; }
  .pt-130 {
    padding-top: 98px !important; }
  .pb-130 {
    padding-bottom: 98px !important; } }

.m-em {
  margin: 1em !important; }

.m-lead-xs {
  margin: 1.25em !important; }

.mt-lead-xs {
  margin-top: 1.25em !important; }

.mb-lead-xs {
  margin-bottom: 1.25em !important; }

.ml-lead-xs {
  margin-left: 1.25em !important; }

.mr-lead-xs {
  margin-right: 1.25em !important; }

.m-lead-sm {
  margin: 1.33333em !important; }

.mt-lead-sm {
  margin-top: 1.33333em !important; }

.mb-lead-sm {
  margin-bottom: 1.33333em !important; }

.ml-lead-sm {
  margin-left: 1.33333em !important; }

.mr-lead-sm {
  margin-right: 1.33333em !important; }

.m-lead-md {
  margin: 1.45454em !important; }

.mt-lead-md {
  margin-top: 1.45454em !important; }

.mb-lead-md {
  margin-bottom: 1.45454em !important; }

.ml-lead-md {
  margin-left: 1.45454em !important; }

.mr-lead-md {
  margin-right: 1.45454em !important; }

.m-lead-lg {
  margin: 1.55556em !important; }

.mt-lead-lg {
  margin-top: 1.55556em !important; }

.mb-lead-lg {
  margin-bottom: 1.55556em !important; }

.ml-lead-lg {
  margin-left: 1.55556em !important; }

.mr-lead-lg {
  margin-right: 1.55556em !important; }

.m-inherit,
.m-bestow > * {
  margin: inherit !important; }

.mt-inherit,
.mt-bestow > * {
  margin-top: inherit !important; }

.mb-inherit,
.mb-bestow > * {
  margin-bottom: inherit !important; }

.ml-inherit,
.ml-bestow > * {
  margin-left: inherit !important; }

.mr-inherit,
.mr-bestow > * {
  margin-right: inherit !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.p-em {
  padding: 1em !important; }

.p-lead-xs {
  padding: 1.25em !important; }

.pt-lead-xs {
  padding-top: 1.25em !important; }

.pb-lead-xs {
  padding-bottom: 1.25em !important; }

.pl-lead-xs {
  padding-left: 1.25em !important; }

.pr-lead-xs {
  padding-right: 1.25em !important; }

.p-lead-sm {
  padding: 1.33333em !important; }

.pt-lead-sm {
  padding-top: 1.33333em !important; }

.pb-lead-sm {
  padding-bottom: 1.33333em !important; }

.pl-lead-sm {
  padding-left: 1.33333em !important; }

.pr-lead-sm {
  padding-right: 1.33333em !important; }

.p-lead-md {
  padding: 1.45454em !important; }

.pt-lead-md {
  padding-top: 1.45454em !important; }

.pb-lead-md {
  padding-bottom: 1.45454em !important; }

.pl-lead-md {
  padding-left: 1.45454em !important; }

.pr-lead-md {
  padding-right: 1.45454em !important; }

.p-lead-lg {
  padding: 1.55556em !important; }

.pt-lead-lg {
  padding-top: 1.55556em !important; }

.pb-lead-lg {
  padding-bottom: 1.55556em !important; }

.pl-lead-lg {
  padding-left: 1.55556em !important; }

.pr-lead-lg {
  padding-right: 1.55556em !important; }

.p-inherit,
.p-bestow > * {
  padding: inherit !important; }

.pt-inherit,
.pt-bestow > * {
  padding-top: inherit !important; }

.pb-inherit,
.pb-bestow > * {
  padding-bottom: inherit !important; }

.pl-inherit,
.pl-bestow > * {
  padding-left: inherit !important; }

.pr-inherit,
.pr-bestow > * {
  padding-right: inherit !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.mb-h {
  margin-bottom: 1.25em !important; }

.mx-auto,
.ml-auto {
  margin-left: auto !important; }

.mx-auto,
.mr-auto {
  margin-right: auto !important; }

.mb-last-0 > *:last-child {
  margin-bottom: 0 !important; }

.mt-first-0 > *:first-child {
  margin-top: 0 !important; }

.ta-left {
  text-align: left !important; }

.ta-right {
  text-align: right !important; }

.ta-center {
  text-align: center !important; }

.ta-justify {
  text-align: justify !important; }

.ta-start {
  text-align: left !important;
  text-align: start !important; }

.ta-end {
  text-align: right !important;
  text-align: end !important; }

.td-under {
  text-decoration-line: underline !important; }

.td-through {
  text-decoration-line: line-through !important; }

.td-over {
  text-decoration-line: overline !important; }

.td-blink {
  text-decoration-line: blink !important; }

.td-none {
  text-decoration-line: none !important; }

.td-wavy {
  text-decoration-style: wavy !important; }

.td-double {
  text-decoration-style: double !important; }

.td-dotted {
  text-decoration-style: dotted !important; }

.td-dashed {
  text-decoration-style: dashed !important; }

.td-solid {
  text-decoration-style: solid !important; }

.inherit-text {
  letter-spacing: inherit !important;
  line-height: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important; }

.serif {
  font-family: "Source Serif Pro", serif !important; }

.sans,
.sans-serif {
  font-family: "Source Sans Pro", sans-serif !important; }

.display {
  font-family: "Oswald", "Source Sans Pro", sans-serif !important; }

.leading-xs {
  line-height: 1.25 !important; }

.leading-sm {
  line-height: 1.33333 !important; }

.leading-md, .leading-normal {
  line-height: 1.45454 !important; }

.leading-lg {
  line-height: 1.55556 !important; }

.lighter {
  font-weight: lighter !important; }

.bolder {
  font-weight: bolder !important; }

.light {
  font-weight: 300 !important; }

.normal {
  font-weight: normal !important; }

.medium,
.semi-bold,
.semibold {
  font-weight: 600 !important; }

.bold {
  font-weight: 700 !important; }

.tt-upper {
  text-transform: uppercase !important; }

.tt-lower {
  text-transform: lowercase !important; }

.tt-none {
  text-transform: none !important; }

.tt-title {
  text-transform: capitalize !important; }
