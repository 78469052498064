.slbElement {
	font-size: 1rem;
	color: $linen;
}

.slbCaption {
	@extend %caption-font;
	height: 5em;
	white-space: normal;
	text-overflow: ellipsis;
	color: rgba($white, 0.9);
	overflow-y: auto;

	@include custom-scrollbar;
}

.slbCloseBtn {
	@extend %caption-font;
	width: auto;
	height: auto;
	color: $linen;
	right: calc(1rem - 5px);
	top: calc(4em - 29px);
	transition: opacity $default-timing;

	&:not(.tab-focus):focus {
		outline: none;
	}

	img {
		display: block;
	}
}

.slbArrow {
	opacity: 1;

	&::before {
	 	opacity: 1;
		transition: opacity time(0.3s) ease;
	 	background: img-url("arrow-circle.svg") center/cover no-repeat;
	 	border: none;
		margin: 0;
		width: 3rem;
		height: 3rem;
	}
	&::after {
		transition: opacity time(0.3s) ease;
		opacity: 0;
		content: "";
		display: block;
		width: 3rem;
		height: 3rem;
		@include centered-absolute;
		background: radial-gradient(circle closest-side, rgba($linen, 0.5), rgba($linen, 0));
	}

	&.prev::before {
		transform: translate(-50%, -50%) rotate(90deg);
	}
	&.next::before {
		transform: translate(-50%, -50%) rotate(-90deg);
	}

	&:hover {
		opacity: 1;
	}

	.hover &:hover,
	&.tab-focus:focus {
		&::before {
			opacity: 0.5;
		}
		&::after {
			opacity: 1;
		}
	}
}

.slbOverlay {
	@keyframes slbOverlay {
		from {
			opacity: 0;
		}
		to {
			opacity: 0.9;
		}
	}

	opacity: 0.9;
}

@include media-breakpoint-down(sm) {
	.slbArrows {
		bottom: 0;
		top: unset;
	}

	.slbArrow {
		bottom: 0;
		top: unset;
		height: 5em;
	}

	.slbCaption {
		max-width: calc(100vw - 12em);
		margin: 0 auto;
		@include custom-scrollbar;
	}
}
