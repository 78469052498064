@at-root .conflict {
	@at-root #weapons {
		overflow: hidden;

		@include media-breakpoint-down(xs) {
			padding-bottom: 4rem !important;
		}
	}

	&-weapons {
		$weapons-bg: rgba($linen, 0.1);
		background-color: $weapons-bg;

		&-slider {
			position: relative;
			z-index: 1;
		}

		&::before,
		&::after {
			@include snippet(pseudo);
			display: block;
			position: absolute;
			height: calc(100% + 2px);
			max-width: 25%;
			border: 1px solid currentColor;
			border-right: transparent;
			top: -1px;
			bottom: 0;
			z-index: 0;
			background: linear-gradient(
						to bottom right,
						currentColor calc(50% - 0.5px),
						transparent calc(50% - 0.5px)
					)
					top/100% 50% no-repeat,
				linear-gradient(
						to top right,
						currentColor calc(50% - 0.5px),
						transparent calc(50% - 0.5px)
					)
					bottom/100% 50% no-repeat,
				linear-gradient(to left, $weapons-bg, transparent) no-repeat;

			$slider-colspans: (
				xl: 11,
				lg: 10,
				md: 10,
				sm: 10,
				xs: 10,
			);
			@each $breakpoint in map-keys($grid-breakpoints) {
				@include media-breakpoint-up($breakpoint) {
					@if (map-has-key($map: $container-max-widths, $key: $breakpoint)) {
						$space: map-get($container-max-widths, $breakpoint) / 2;
						$col-ratio: map-get($slider-colspans, $breakpoint) /
							$grid-columns;
						width: calc(50vw - #{floor($space * $col-ratio)});
					} @else {
						width: 12.5vw;
					}
				}
			}
		}

		&::before {
			left: 0px;
			transform: translateX(-100%);
		}

		&::after {
			right: 0px;
			transform: translateX(100%) rotate(0.5turn);
		}
	}

	@at-root .weapon {
		$weapon-card-border: 5px;

		> figure,
		&-description {
			backface-visibility: hidden;
			transition: transform time(0.3s) cubic-bezier(0.445, 0.05, 0.55, 0.95);

			@include a11y-reduce-motion;

		}

		@include media-portrait {
			& .weapon-content-scroll,
			&-image {
				@include media-breakpoint-down(sm) {
					padding-top: 1em;
					padding-bottom: 1em;
				}
				@include media-breakpoint-down(xs) {
					padding-top: 2em;
					padding-bottom: 2em;
				}
			}

			&-description-content {
				@include firefox-only {
					@include media-breakpoint-down(sm) {
						padding-bottom: 1.5em;
					}
					@include media-breakpoint-down(xs) {
						padding-bottom: 2.5em;
					}
				}
			}
		}

		figure {
			background: $linen;
			&,
			& > img {
				width: 100%;
			}

			&::before {
				height: 50%;
				top: unset;
				bottom: 0;

				@each $name, $color in $theme-colors {
					.#{$name}-bg & {
						background: linear-gradient(to top, $color, rgba($color, 0)), rgba($color, 0);
					}
				}
			}

			.hover &:hover,
			&.tab-focus-within {
				&::before {
					opacity: 0.7;
				}
			}

			figcaption {
				font-family: $font-family-display;
				letter-spacing: tracking(40);

				@include media-breakpoint-only(xs) {
					// Clear weapon card button
					padding-left: 52px;
					padding-right: 52px;
				}
			}

			&.tab-focus-within figcaption {
				opacity: 1;
			}

			@include media-breakpoint-down(sm) {
				&::before {
					opacity: 0.7;
				}
				figcaption {
					opacity: 1;
				}
			}
		}

		&-image {
			$nat-height: 550px;
			$nat-width: 1072px;
			$ratio: $nat-height / $nat-width;
			box-sizing: content-box;
			height: calc((100vw - #{$grid-gutter-width}) * 11 / 12 * #{$ratio});
			@include media-breakpoint-up(sm) {
				height: 450px * $ratio;
			}
			@include media-breakpoint-up(md) {
				height: 600px * $ratio;
			}
			@include media-breakpoint-up(lg) {
				height: 800px * $ratio;
			}
			@include media-breakpoint-up(xl) {
				height: $nat-height;
			}
		}

		&-content-scroll {
			overflow-y: auto;
			@extend %custom-scrollbar;
			padding: 5%;
			border: $weapon-card-border solid $linen;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;

			> div {
				flex: 1 0 auto;
			}
		}

		&-description {
			transform: rotateX(180deg);
			z-index: $layer-bg;

			@at-root .weapon-content-scroll,
			&,
			&::before,
			& > img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			&::before {
				@include snippet(pseudo);
				z-index: $layer-bg-overlay;
				background-color: inherit;
				opacity: 0.9;
				pointer-events: none;
			}

			img {
				pointer-events: none;
				z-index: $layer-content;
				opacity: 0.4;
			}

			&-content {
				position: relative;
				z-index: $layer-content-super;

				p {
					max-width: 25em;
				}

				.h4 {
					&::after {
						@include snippet(pseudo);
						width: 140px;
						border-top: 1px solid currentColor;
						display: block;
						margin: 0.75em auto -0.75em;
					}
				}

				@include media-breakpoint-only(xs) {
					p {
						font-size: 1rem;
					}
				}
			}
		}

		&-button {
			color: $black;
			position: absolute;
			right: $weapon-card-border;
			bottom: $weapon-card-border;
			margin: $weapon-card-border;
			z-index: $layer-overtop;
			svg {
				pointer-events: none;
			}
			.inline-svg {
				position: relative;

				&::before {
					@include snippet(pseudo-overtop)
				}
			}

			#arrows,
			#bg {
				transition: color time(0.3s) ease;
			}

			#arrows {
				color: white;
			}

			#bg {
				color: transparent;
				fill: currentColor;
			}

			@include a11y-focus(":hover") {
				#arrows {
					color: $linen
				}

				#bg {
					@each $name, $color in $theme-colors {
						.#{$name}-bg & {
							color: mix($white, mix($color, $linen, 70%), 1%)
						}
					}
				}
			}
		}

		&.active > figure {
			transform: rotateX(180deg);
		}
		&.active > &-description {
			transform: rotateX(0);
			z-index: initial;
		}
	}

	@at-root .slider {
		&-nav {
			@extend .pager-nav;
			// color: $linen;
			color: $white;
			transform: translate(calc(100% + 2rem), -50%);

			@include media-breakpoint-down(sm) {
				transform: translate(calc(100% + 1rem), -50%);
			}

			@include js-only {
				display: flex;
			}
		}
		&-prev {
			@extend .pager-prev;
		}
		&-next {
			@extend .pager-next;
		}
		&-meta {
			@extend .pager-meta;
			flex-direction: inherit;
			align-items: inherit;
		}
		&-total {
			@extend .pager-total;
		}
		&-current {
			@extend .pager-current;
		}
		&-sep {
			@extend .pager-sep;
		}
		&-inactive {
			@extend .pager-inactive;
		}
		@include media-breakpoint-down(md) {
			&-prev,
			&-next {
				img {
					transform-origin: top;
					transform: scale(0.75);
				}
			}
		}
		@include media-breakpoint-only(xs) {
			&-inactive {
				opacity: 0.2;
				visibility: visible;
				cursor: default !important;
			}
			&-prev,
			&-next {
				order: 2;
				min-width: $touch-target-size;
				img {
					transform-origin: center;
				}
			}
			&-nav {
				flex-direction: row;
				top: 100%;
				left: 50%;
				transform: translate(-50%, 0.5rem);
				align-items: center;
				justify-content: center;
				width: 100%;
			}
			&-sep {
				transform: rotate(90deg);
			}
			&-meta {
				margin: 0 auto 0 (4em / 3);
			}
		}
	}
}
