main {
	counter-reset: section;
}

@counter-style section {
}

.-faded {
	$fade-height: 25%;
	@include pseudo-fade($navy, $fade-height, $fade-height, "common");
	&::after {
		bottom: -1px;
	}

	.green-bg & {
		@include pseudo-fade($green, $fade-height, $fade-height, "pseudo");
	}
	.navy-bg & {
		@include pseudo-fade($navy, $fade-height, $fade-height, "pseudo");
	}
	.charcoal-bg & {
		@include pseudo-fade($charcoal, $fade-height, $fade-height, "pseudo");
	}
	.brown-bg & {
		@include pseudo-fade($brown, $fade-height, $fade-height, "pseudo");
	}
}

.section {
	padding-top: 2em;
	padding-bottom: 2em;

	&-copy {
		padding-left: $grid-gutter-width;
		@include media-breakpoint-down(xs) {
			padding-right: $grid-gutter-width;
		}
	}

	&.-numbered {
		counter-increment: section;
	}

	&-title {
		@extend %h4-font;
		text-transform: uppercase;
		font-family: $font-family-display;
		font-weight: $font-weight-bold;
		color: $linen;
		opacity: 0.5;
		position: relative;
		margin-top: 2em;
		margin-bottom: 2em;

		.section.-numbered &::before {
			content: "0" counter(section) !important;
			@include font-size(px-to-rem(64px));
			line-height: 1;
			opacity: 0.6;
			position: absolute;

			@include media-breakpoint-only(xs) {
				bottom: calc(100% + 0.25em);
			}

			@include media-breakpoint-up(sm) {
				bottom: 0;
				transform: translate(-100%, 0);
				left: -0.375em;
			}
			@include media-breakpoint-up(md) {
				left: -0.875em;
			}
			@include media-breakpoint-up(lg) {
				left: -1.175em;
			}
		}
	}
}
