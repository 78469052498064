$social-icon-gap: 1rem;

.social-icons {
	@extend %row;
	@extend %unlist;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin: -($social-icon-gap / 2);

	li {
		display: block;
		padding: ($social-icon-gap / 2);
	}

	a {

	}

	img {

	}
}
