@mixin when-pager-active {
	@include media-breakpoint-up(lg) {
		html:not(.no-js) & {
			@content;
		}
	}
}
@mixin when-pager-inactive {
	html.no-js & {
		@content;
	}
	// $size: map-get($map: $grid-breakpoints, $key: lg);
	@include media-breakpoint-down(md) {
		@content;
	}
	@include media-reduced-motion {
		@content;
	}
}

.pager {
	&-deck {
		position: relative;
		min-height: 100vh;

		&:not(.has-pager-expanded) {
			overflow: hidden;
		}

		&.has-pager-expanded {
			@include when-pager-inactive {
				.pager-panel:not(.pager-expanded) {
					display: none;
				}
			}
		}
	}

	&-panel {
		position: relative;
		width: 100%;
		min-height: inherit;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		@include when-pager-active {
			position: absolute;
			top: 0;
			left: 0;
			perspective: 1800px;
			opacity: 0;
			visibility: hidden;

			&:not(:first-child) {
				perspective-origin: center 0;
			}

			&:not(.pager-expanded) {
				max-height: 100%;
			}
		}
		.has-pager-expanded > &:not(.pager-expanded) {
			@include when-pager-active {
				display: none;
			}
		}
	}

	&-panel-body {
		position: relative;
		width: 100%;
		min-height: inherit;
		overflow: hidden;
		// display: flex;
		// justify-content: center;

		// align-items: center;
		visibility: visible;

		transition: min-height 0.5s ease, padding 0.5s ease;

		@include media-breakpoint-up(sm) {
			padding-left: 4rem;
			padding-right: 4rem;
		}

		@include when-pager-active {
			/* Force Hardware Acceleration */
			transform: translateZ(0);
			backface-visibility: hidden;
			transform-origin: center bottom;
		}

		@include when-pager-inactive {
			transform: none !important;
		}
	}

	&-nav {
		position: absolute;
		z-index: $layer-content-super;
		right: 0;
		top: 50%;
		bottom: auto;
		transform: translate(-2.25rem, -50%);
		display: none;
		flex-flow: column;
		text-align: center;
		@include font-size(1.125rem);
		line-height: 1.2;
		opacity: 1;
		visibility: visible;
		transition: opacity time(0.2s) 0s, visibility 0s time(0.2s);

		@include when-pager-active {
			display: flex;
		}

		@include edge-only {
			.pager-expanded ~ & {
				display: none !important;
			}
		}

		#conflicts-intro.pager-visible ~ & {
			opacity: 0;
			visibility: hidden;
			transition: none;
		}
	}

	&-prev,
	&-next {
		display: block;
		position: relative;
		overflow: visible;
		transition: opacity time(0.2s) 0s;

		img {
			transition: opacity time(0.3s) ease;
		}

		@include a11y-focus(":hover") {
			&:not([class*="inactive"]) {
				&::before {
					opacity: 1;
				}
				img {
					opacity: 0.5;
				}
			}
		}

		&::before {
			background: radial-gradient(
				circle closest-side,
				rgba($linen, 0.25),
				rgba($linen, 0)
			);
			opacity: 0;
			transition: opacity time(0.5s) ease;
		}
	}

	&-prev {
		transform: rotate(180deg);
	}

	&-inactive {
		visibility: hidden;
		opacity: 0;
		transition: opacity time(0.2s) 0s, visibility 0s time(0.2s);
	}

	&-meta {
		margin: (4em / 3) 0;
		display: flex;
		flex-flow: column;
	}

	&-current,
	&-sep,
	&-total {
		display: block;
	}

	&-sep {
		width: 0.95em;
		border-bottom: 2px solid currentColor;
		margin: (1em / 3) auto;
	}

	&-expanded {
		&.pager-panel {
			min-height: unset;
			overflow: unset;
			visibility: visible !important;
			z-index: $layer-content-super;
		}

		~ .pager-nav {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}
	}
}
