%row,
.row {
	&.-center {
		justify-content: center;
	}
	&.-spread {
		justify-content: space-between;
	}
	&.-distribute {
		justify-content: space-evenly;
	}
	&.-equal {
		justify-content: space-around;
	}
}

.row > br {
	content: "";
	display: block;
	flex-basis: 100%;
	width: 100%;
	height: 0;
	margin: 0;
	border: 0;
}

// .max-col {
//     @for $column-count from 1 through $grid-columns {
//         &-#{$column-count} {
//             max-width: calc(#{$column-count / $grid-columns} * 100vw);
//         }
//     }
// }


// creates breakpointed .inset-<breakpoint>-<columns> classes
// exactly like .offset, but with negative margins
@each $breakpoint in map-keys($map: $grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint);
    @if $breakpoint == xs {
        $infix: '';
    }
    @include media-breakpoint-up($breakpoint) {
        // @for $column-count from 1 through $grid-columns {
        @for $column-count from 0 through 6 {
            .inset#{$infix}-#{$column-count} {
                margin-left: percentage($column-count / $grid-columns) * -1;
            }
            .inset-next#{$infix}-#{$column-count} {
                margin-right: percentage($column-count / $grid-columns) * -1;
            }
		}

		.offset#{$infix}-half {
			margin-left: percentage(0.5 / $grid-columns)
		}
    }
}


